/*
author: Hf.Platform.Seth.Wang
Date: July,2017

*/
import Queue from '../common/queue';

var AudioData = (function () {
  function AudioData(buffer, timeStamp) {
    this.timeStamp = timeStamp;
    this.buf = buffer;
  }
  return AudioData;
})();

var VideoData = (function () {
  function VideoData(buffer, timeStamp) {
    this.timeStamp = timeStamp;
    this.buf = buffer;
  }
  return VideoData;
})();

function AudioQueueMGR() {
  this.ssrcQueueMap = new Map();
  AudioQueueMGR.prototype.AddQueue = function (key) {
    var queue = new Queue();
    this.ssrcQueueMap.set(key, queue);
    return queue;
  };
  AudioQueueMGR.prototype.DeleteQueue = function (key) {
    this.ssrcQueueMap.delete(key);
  };
  AudioQueueMGR.prototype.GetQueue = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    return queue_;
  };
  AudioQueueMGR.prototype.GetQueueData = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    return queue_.dequeue();
  };
  AudioQueueMGR.prototype.PutQueueData = function (key, data) {
    var queue_ = this.ssrcQueueMap.get(key);
    queue_.enqueue(data);
  };
  AudioQueueMGR.prototype.GetQueueLength = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    if (queue_ !== null) {
      return queue_.getLength();
    }
    return 0;
  };
}
var VideoInfo = function () {
  this.frames = 0;
  this.ntp = new Queue();
};
VideoInfo.prototype = {
  UpdateVideoInfo: function (ntptime) {
    this.frames++;
    if (this.ntp.getLength() > 30) {
      this.ntp.dequeue();
    }
    this.ntp.enqueue(ntptime);
  },
  GetVideoFpsInfo: function () {
    var length = this.ntp.getLength();
    if (length < 5) {
      return;
    }
    var fps = 0;
    var NotZeroTime = 0;
    var ZeroNTPNumb = 0;
    var StartNTPNotZero = 0;
    var lastNotZero = 0;
    for (var i = 0; i < length; i++) {
      var ntpTime = this.ntp.dequeue();
      if (ntpTime == 0 && StartNTPNotZero == 0) {
        ZeroNTPNumb++;
      } else if (ntpTime != 0) {
        lastNotZero = 0;
        if (StartNTPNotZero == 0) {
          StartNTPNotZero = ntpTime;
        }
        NotZeroTime = ntpTime;
      } else if (
        StartNTPNotZero != 0 &&
        NotZeroTime != StartNTPNotZero &&
        ntpTime == 0
      ) {
        lastNotZero++;
      }
    }

    if (NotZeroTime == 0 || NotZeroTime == StartNTPNotZero) {
      fps = 0;
    } else {
      if (length - ZeroNTPNumb - lastNotZero - 1 != 0) {
        fps =
          (NotZeroTime - StartNTPNotZero) /
          (length - ZeroNTPNumb - lastNotZero - 1);
      } else {
        fps = 0;
      }
    }
    return fps;
  },
};
var VideoInfoMGR = function () {
  this.ssrcInfoMap = new Map();
};
VideoInfoMGR.prototype = {
  UpdateSSRCInfo: function (ssrc, ntp) {
    var info = this.ssrcInfoMap.get(ssrc);
    if (info) {
      info.UpdateVideoInfo(ntp);
    } else {
      info = new VideoInfo();
      this.ssrcInfoMap.set(ssrc, info);
      info.UpdateVideoInfo(ntp);
    }
  },
  GetSSRCFpsInfo: function (ssrc) {
    var info = this.ssrcInfoMap.get(ssrc);
    if (info) {
      return info.GetVideoFpsInfo();
    } else {
      return 0;
    }
  },
};

function VideoQueueMGR() {
  this.ssrcQueueMap = new Map();
  this.ssrcInfo = new VideoInfoMGR();

  VideoQueueMGR.prototype.AddQueue = function (key) {
    var queue = new Queue();
    this.ssrcQueueMap.set(key, queue);
    return queue;
  };
  VideoQueueMGR.prototype.DeleteQueue = function (key) {
    this.ssrcQueueMap.delete(key);
  };
  VideoQueueMGR.prototype.GetQueue = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    return queue_;
  };
  VideoQueueMGR.prototype.ClearQueue = function () {
    this.ssrcQueueMap.clear();
    return;
  };
  VideoQueueMGR.prototype.GetQueueData = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    return queue_.dequeue();
  };
  VideoQueueMGR.prototype.PutQueueData = function (key, data) {
    var queue_ = this.ssrcQueueMap.get(key);
    queue_.enqueue(data);
  };
  VideoQueueMGR.prototype.GetQueueLength = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    if (queue_) {
      return queue_.getLength();
    }
    return 0;
  };
  VideoQueueMGR.prototype.UpdateInfo = function (ssrc, ntp) {
    this.ssrcInfo.UpdateSSRCInfo(ssrc, ntp);
  };

  VideoQueueMGR.prototype.GetFpsInfo = function (ssrc) {
    return this.ssrcInfo.GetSSRCFpsInfo(ssrc);
  };
}
var AudioMGR = (function () {
  function AudioMGR({ sessionid }) {
    this.map = new Map();
    this.AudioQueueMGR = new AudioQueueMGR();
    this.timemap = new Map();
    this.sessionid = sessionid || 0;
  }
  AudioMGR.prototype.Add = function (ssrc, audio_webwork) {
    this.map.set(ssrc, audio_webwork);
    this.AudioQueueMGR.AddQueue(ssrc);
  };
  AudioMGR.prototype.Clear = function () {
    this.map.clear();
  };
  AudioMGR.prototype.Keys = function () {
    return this.map.keys();
  };
  AudioMGR.prototype.UpdateSSRCTimeMap = function (timestamp) {
    this.timemap = timestamp;
  };
  AudioMGR.prototype.GetSSRCTimeMap = function (ssrc) {
    if (this.timemap) {
      return this.timemap.get(ssrc);
    }
    return null;
  };
  return AudioMGR;
})();

function VideoMGR({ sessionid }) {
  this.sessionid = sessionid || 0;
  this.map = new Map();
  this.VideoQueueMGR = new VideoQueueMGR();
}
VideoMGR.prototype.Add = function (ssrc, video_webwork) {
  this.map.set(ssrc, video_webwork);
};
VideoMGR.prototype.Clear = function () {
  this.map.clear();
};
VideoMGR.prototype.Keys = function () {
  return this.map.keys();
};

//----------------------------------sharing
var SharingInfo = function () {
  this.frames = 0;
  this.ntp = new Queue();
};
SharingInfo.prototype = {
  UpdateSharingInfo: function (ntptime) {
    this.frames++;
    if (this.ntp.getLength() > 30) {
      this.ntp.dequeue();
    }
    this.ntp.enqueue(ntptime);
  },
  GetSharingFpsInfo: function () {
    var length = this.ntp.getLength();
    if (length < 5) {
      return;
    }
    var fps = 0;
    var NotZeroTime = 0;
    var ZeroNTPNumb = 0;
    var StartNTPNotZero = 0;
    var lastNotZero = 0;
    for (var i = 0; i < length; i++) {
      var ntpTime = this.ntp.dequeue();
      if (ntpTime == 0 && StartNTPNotZero == 0) {
        ZeroNTPNumb++;
      } else if (ntpTime != 0) {
        lastNotZero = 0;
        if (StartNTPNotZero == 0) {
          StartNTPNotZero = ntpTime;
        }
        NotZeroTime = ntpTime;
      } else if (
        StartNTPNotZero != 0 &&
        NotZeroTime != StartNTPNotZero &&
        ntpTime == 0
      ) {
        lastNotZero++;
      }
    }

    if (NotZeroTime == 0 || NotZeroTime == StartNTPNotZero) {
      fps = 0;
    } else {
      if (length - ZeroNTPNumb - lastNotZero - 1 != 0) {
        fps =
          (NotZeroTime - StartNTPNotZero) /
          (length - ZeroNTPNumb - lastNotZero - 1);
      } else {
        fps = 0;
      }
    }
    return fps;
  },
};
var SharingInfoMGR = function () {
  this.ssrcInfoMap = new Map();
};
SharingInfoMGR.prototype = {
  UpdateSSRCInfo: function (ssrc, ntp) {
    var info = this.ssrcInfoMap.get(ssrc);
    if (info) {
      info.UpdateSharingInfo(ntp);
    } else {
      info = new SharingInfo();
      this.ssrcInfoMap.set(ssrc, info);
      info.UpdateSharingInfo(ntp);
    }
  },
  GetSSRCFpsInfo: function (ssrc) {
    var info = this.ssrcInfoMap.get(ssrc);
    if (info) {
      return info.GetSharingFpsInfo();
    } else {
      return 0;
    }
  },
};
function SharingQueueMGR() {
  this.ssrcQueueMap = new Map();
  this.ssrcInfo = new SharingInfoMGR();

  SharingQueueMGR.prototype.AddQueue = function (key) {
    var queue = new Queue();
    this.ssrcQueueMap.set(key, queue);
    return queue;
  };
  SharingQueueMGR.prototype.DeleteQueue = function (key) {
    this.ssrcQueueMap.delete(key);
  };
  SharingQueueMGR.prototype.GetQueue = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    return queue_;
  };
  SharingQueueMGR.prototype.ClearQueue = function () {
    this.ssrcQueueMap.clear();
    return;
  };
  SharingQueueMGR.prototype.GetQueueData = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    return queue_.dequeue();
  };
  SharingQueueMGR.prototype.PutQueueData = function (key, data) {
    var queue_ = this.ssrcQueueMap.get(key);
    queue_.enqueue(data);
  };
  SharingQueueMGR.prototype.GetQueueLength = function (key) {
    var queue_ = this.ssrcQueueMap.get(key);
    if (queue_) {
      return queue_.getLength();
    }
    return 0;
  };
  SharingQueueMGR.prototype.UpdateInfo = function (ssrc, ntp) {
    this.ssrcInfo.UpdateSSRCInfo(ssrc, ntp);
  };

  SharingQueueMGR.prototype.GetFpsInfo = function (ssrc) {
    return this.ssrcInfo.GetSSRCFpsInfo(ssrc);
  };
}
function SharingMGR({ sessionid }) {
  this.map = new Map();
  this.sQueue = new Queue();
  this.SharingQueueMGR = new SharingQueueMGR();
  this.sessionid = sessionid || 0;
}

SharingMGR.prototype.Clear = function () {
  this.map.clear();
};
SharingMGR.prototype.Keys = function () {
  return this.map.keys();
};
SharingMGR.prototype.Add = function (ssrc, sharing_webwork) {
  this.map.set(ssrc, sharing_webwork);
};
SharingMGR.prototype.Get = function (ssrc) {
  return this.map.get(ssrc);
};
SharingMGR.prototype.PutData = function (data) {
  if (data && this.sQueue) this.sQueue.enqueue(data);
};
SharingMGR.prototype.GetData = function () {
  if (this.sQueue) {
    return this.sQueue.dequeue();
  }
};
SharingMGR.prototype.ClearBuffer = function () {
  if (this.sQueue) {
    this.sQueue = new Queue();
  }
};

export {
  AudioData,
  VideoData,
  AudioQueueMGR,
  VideoInfo,
  VideoInfoMGR,
  VideoQueueMGR,
  SharingInfo,
  SharingInfoMGR,
  SharingQueueMGR,
  AudioMGR,
  VideoMGR,
  SharingMGR,
};

import Zoom_Monitor from './Monitor';

export class ComputePressureManager {
  static isSupportComputePressure = () =>
    typeof PressureObserver === 'function';

  constructor() {
    this.pressureLevel = {
      nominal: 0,
      fair: 1,
      serious: 2,
      critical: 3,
    };
    this.observer = new PressureObserver((changes) => {
      // nominal fair serious critical
      this.result = this.pressureLevel[changes[0].state];
      // CPC: compute pressure change
      Zoom_Monitor.add_monitor('CPC:' + this.result);
    });
  }

  init() {
    this.observer.observe('cpu');
  }

  destroy() {
    this.observer.unobserve('cpu');
  }
}

import GPUBufferPoolEntry from './GPUBufferPoolEntry';
import {
  GPU_RES_TYPE,
  RES_OCCUPANCY_LEVEL,
  RESOLUTION_LEVELS,
  HIG_RSL_LEVEL_OFFSET,
} from './RenderConst';
import { add_monitor } from '../../worker/common/common';

/**
 * GPUBufferPool helps to create and manage GPUBuffers.
 *
 * Different from GPUBufferManager, GPUBufferPool will not classify which rendering display
 * requests GPUBuffers, all the GPUBuffers will be managed together instead. Meanwhile,
 * GPUBufferPool is more dynamic way to allocate resources.
 */
class GPUBufferPool {
  #mResType = GPU_RES_TYPE.TEXTURE_BUFFER;
  #mResInfo = {};
  #mDevice = null;
  #mInUsedPool = [];
  #mAvailablePool = new Map();
  #createdCount = 0;

  constructor(device) {
    this.#mDevice = device;
  }

  #adjustBufferConfig(bufferConfig) {
    if (!bufferConfig) {
      return;
    }

    const colorFormat = bufferConfig.colorFormat;
    if (colorFormat == 'rgba') {
      const level = this.#queryClosestResolutionLevel(bufferConfig.height);
      if (level > 0 && level < RESOLUTION_LEVELS[HIG_RSL_LEVEL_OFFSET]) {
        bufferConfig.size = bufferConfig.width * level;
        bufferConfig.height = level;
      }
      bufferConfig.level = level;
      bufferConfig.bytesPerRow = bufferConfig.width;
    } else if (colorFormat == 'i420') {
      const level = this.#queryClosestResolutionLevel(
        bufferConfig.yPlane.height
      );
      if (level > 0 && level < RESOLUTION_LEVELS[HIG_RSL_LEVEL_OFFSET]) {
        bufferConfig.size =
          bufferConfig.yPlane.width * level +
          bufferConfig.uvPlane.width * level;
        bufferConfig.yPlane.height = level;
        bufferConfig.uvPlane.height = level / 2;
      }
      bufferConfig.level = level;
      bufferConfig.bytesPerRow = bufferConfig.yPlane.width;
    } else if (colorFormat == 'nv12') {
      const level = this.#queryClosestResolutionLevel(
        bufferConfig.yPlane.height
      );
      if (level > 0 && level < RESOLUTION_LEVELS[HIG_RSL_LEVEL_OFFSET]) {
        bufferConfig.size =
          bufferConfig.yPlane.width * level +
          (bufferConfig.uvPlane.width * level) / 2;
        bufferConfig.yPlane.height = level;
        bufferConfig.uvPlane.height = level / 2;
      }
      bufferConfig.level = level;
      bufferConfig.bytesPerRow = bufferConfig.yPlane.width;
    }
  }

  #queryClosestResolutionLevel(height) {
    if (height <= 0) {
      return 0;
    }

    let level = RESOLUTION_LEVELS[HIG_RSL_LEVEL_OFFSET];
    for (let i = 0; i < RESOLUTION_LEVELS.length; ++i) {
      if (height <= RESOLUTION_LEVELS[i]) {
        level = RESOLUTION_LEVELS[i];
        break;
      }
    }

    return level;
  }

  #queryNextResolutionLevel(level) {
    const index = RESOLUTION_LEVELS.indexOf(level);
    if (index > -1 && index + 1 <= RESOLUTION_LEVELS.length - 1) {
      return RESOLUTION_LEVELS[index + 1];
    }

    return level;
  }

  #evalPoolThreshold(bufferConfig) {
    if (!bufferConfig) {
      return 0;
    }

    let rs = 0;
    const colorFormat = bufferConfig.colorFormat;
    if (colorFormat == 'rgba') {
      rs = bufferConfig.height;
    } else if (colorFormat == 'i420' || colorFormat == 'nv12') {
      rs = bufferConfig.yPlane.height;
    }

    if (rs == 0) {
      return 0;
    }

    let poolThreshold = 0;
    if (rs <= RESOLUTION_LEVELS[2]) {
      poolThreshold = 90;
    } else if (rs > RESOLUTION_LEVELS[2] && rs <= RESOLUTION_LEVELS[5]) {
      poolThreshold = 60;
    } else {
      poolThreshold = 15;
    }

    return poolThreshold;
  }

  /**
   * Acquire a GPUBuffer.
   *
   * @param {*} bufferConfig the configuration of initializing a GPUBuffer
   * @returns null or a GPUBuffer with mapped state
   */
  acquire(bufferConfig) {
    if (!bufferConfig) {
      throw new Error('acquire() bufferConfig is invalid!');
    }

    // adjust the buffer config to get standard bytesPerRow and height,
    // meanwhile, add more attributes to it
    this.#adjustBufferConfig(bufferConfig);

    let gpuBuffer = null;
    let pool = null;
    if (this.#mAvailablePool.size == 0) {
      // available pool is empty, need to create a new gpu buffer
      if (this.#mDevice) {
        const buffer = this.#mDevice.createBuffer({
          label: bufferConfig.label,
          size: bufferConfig.size,
          usage: bufferConfig.usage,
          mappedAtCreation: true,
        });

        let needToSort = false;
        if (!pool) {
          const poolThreshold = this.#evalPoolThreshold(bufferConfig);
          pool = new GPUBufferPoolEntry(bufferConfig.level, poolThreshold);
          needToSort = true;
        }

        if (buffer) {
          this.#createdCount += 1;
          gpuBuffer = buffer;
          gpuBuffer.label = `${bufferConfig.label}-${this.#createdCount}`;
        }

        this.#setEntryToAvailablePool(bufferConfig.level, pool, needToSort);
      }
    } else {
      if (this.#mAvailablePool.has(bufferConfig.level)) {
        pool = this.#mAvailablePool.get(bufferConfig.level);
        let needToSort = false;
        if (!pool) {
          const poolThreshold = this.#evalPoolThreshold(bufferConfig);
          pool = new GPUBufferPoolEntry(bufferConfig.level, poolThreshold);
          needToSort = true;
        }

        gpuBuffer = pool.acquire(bufferConfig);
        if (!gpuBuffer) {
          gpuBuffer = this.#borrowBufferFromNextLevel(bufferConfig);
          if (!gpuBuffer) {
            if (
              !pool.isUpToThreshold(
                bufferConfig.level,
                bufferConfig.bytesPerRow
              )
            ) {
              const buffer = this.#mDevice.createBuffer({
                label: bufferConfig.label,
                size: bufferConfig.size,
                usage: bufferConfig.usage,
                mappedAtCreation: true,
              });

              if (buffer) {
                this.#createdCount += 1;
                gpuBuffer = buffer;
                gpuBuffer.label = `${bufferConfig.label}-${this.#createdCount}`;
              }
            } else {
              console.log(
                `[GPUBufferPool]acquire() next level cant help and pool is up to threshold! Only to wait for a while...`
              );
            }
          }
        } else {
          gpuBuffer.label = `${bufferConfig.label}-${this.#createdCount}`;
        }

        if (needToSort) {
          this.#setEntryToAvailablePool(bufferConfig.level, pool, needToSort);
        }
      } else {
        // when code is executed here, that is, the available pool doesn't have any matched buffer that can provide.
        // now, we have another alternative solution to optimize the performance. We can query a buffer which has bigger
        // size than the target size, for example, the pending buffer size is 1000, if no available buffers with the same
        // size, we can check whether have buffers that the size of them are bigger than 1000, if any, return it.
        let needToSort = false;
        gpuBuffer = this.#borrowBufferFromNextLevel(bufferConfig);
        if (!gpuBuffer) {
          const buffer = this.#mDevice.createBuffer({
            label: bufferConfig.label,
            size: bufferConfig.size,
            usage: bufferConfig.usage,
            mappedAtCreation: true,
          });

          if (!pool) {
            const poolThreshold = this.#evalPoolThreshold(bufferConfig);
            pool = new GPUBufferPoolEntry(bufferConfig.level, poolThreshold);
            needToSort = true;
          }

          if (buffer) {
            this.#createdCount += 1;
            gpuBuffer = buffer;
            gpuBuffer.label = `${bufferConfig.label}-${this.#createdCount}`;
          }
        } else {
          gpuBuffer.label = `${bufferConfig.label}-${this.#createdCount}`;
        }

        if (needToSort) {
          this.#setEntryToAvailablePool(bufferConfig.level, pool, needToSort);
        }
      }
    }

    if (gpuBuffer) {
      this.#mInUsedPool.push(gpuBuffer);
    }

    return gpuBuffer;
  }

  /**
   * Recycle a GPUBuffer.
   *
   * @param {*} buffer a GPUBuffer will be recycled
   */
  recycle(buffer, bufferConfig) {
    if (!buffer) {
      return;
    }

    const index = this.#mInUsedPool.indexOf(buffer);
    if (index != -1) {
      this.#mInUsedPool.splice(index, 1);
    } else {
      console.error(
        `[BufferPool] buffer can't be recycled. bufferConfig:${JSON.stringify(
          bufferConfig
        )}`
      );

      add_monitor(
        `[BufferPool] buffer can't be recycled. bufferConfig:${JSON.stringify(
          bufferConfig
        )}`
      );
    }

    if (!bufferConfig) {
      buffer.destroy();
      return;
    }

    // to recycle a buffer, first to call mapAsync and make it mapped again for next round
    if (buffer.mapState != 'unmapped') {
      buffer.unmap();
    }

    // check the mapState again, it should be unmapped if want to call mapAsync()
    if (buffer.mapState == 'unmapped') {
      this.#mapAsyncBuffer(buffer);
    }

    // once mapped, put it to the available pool again
    let pool = this.#mAvailablePool.get(bufferConfig.level);
    if (pool) {
      pool.recycle(bufferConfig.level, bufferConfig.bytesPerRow, buffer);
    }

    buffer.label = '';
  }

  /**
   * Recycle GPUBuffers from texture layers.
   *
   * @param {*} texLayersMap GPUBuffers are saved in it
   * @param {*} resourceMgr used to manage GPU resource components
   */
  recycleInUsedGPUBuffers(texLayersMap, resourceMgr) {
    for (const [zIndex, texLayers] of texLayersMap) {
      for (const texLayer of texLayers) {
        if (texLayer) {
          const texBufferGroup = texLayer.getTextureBufferGroup();
          if (texBufferGroup && texBufferGroup.buffer) {
            if (texBufferGroup.bufferArray) {
              texBufferGroup.bufferArray = null;
            }

            this.recycle(texBufferGroup.buffer, texBufferGroup.bufferConfig);
          } else {
            // const count = this.#mBufferPool.getInUsedPoolCount();
            // console.log(`renderNoMsaa() recycle is skip! inUsedPoolCount=${count}`);
          }
          texLayer.destroyTextureBufferGroup(resourceMgr);
        }
      }
    }
  }

  /**
   * Recycle GPUBuffers from a texture layer.
   *
   * @param {*} texLayer a texture layer which saves GPUBuffers for texture group
   * @param {*} resourceMgr used to manage GPU resource components
   */
  recycleTextureBufferGroup(texLayer, resourceMgr) {
    if (texLayer) {
      if (resourceMgr) {
        const bufferPool = resourceMgr.acquireGPUBufferPool();
        if (bufferPool) {
          const bufferGroup = texLayer.getTextureBufferGroup();
          if (bufferGroup && bufferGroup.buffer) {
            if (bufferGroup.bufferArray) {
              bufferGroup.bufferArray = null;
            }
            bufferPool.recycle(bufferGroup.buffer, bufferGroup.bufferConfig);
            texLayer.destroyTextureBufferGroup(resourceMgr);
          }
        }
      }
    }
  }

  /**
   * Change a GPUBuffer's mapState to mapped
   *
   * @param {*} buffer a GPUBuffer whose mapState will be changed
   */
  #mapAsyncBuffer(buffer) {
    buffer.mapAsync(GPUMapMode.WRITE, 0, buffer.size).then(() => {
      // console.log(`mapAsyncBuffer() a buffer is mapped and available now! size=${buffer.size} label=${buffer.label}`);
    });
  }

  /**
   * Cleanup all the GPUBuffers in in-used pool and available pool.
   * It should be used when the meeting stops.
   */
  cleanup() {
    for (const buffer of this.#mInUsedPool) {
      if (buffer.mapState != 'unmapped') {
        buffer.unmap();
      }
      buffer.destroy();
    }
    this.#mInUsedPool.length = 0;

    for (const [key, val] of this.#mAvailablePool) {
      if (val) {
        val.cleanup();
      }
    }

    this.#mAvailablePool.clear();
  }

  /**
   * Release the GPUBuffers in the available pool.
   * It should be used when the GPU resources are nervous.
   *
   * @param {*} level an occupancy level helps to decide how to release resources
   */
  release(level) {
    if (level == RES_OCCUPANCY_LEVEL.OVERUSE) {
      for (const [key, val] of this.#mAvailablePool) {
        if (val) {
          val.release(level);
        }
      }
      this.#mAvailablePool.clear();
    }
  }

  getResourceType() {
    return this.#mResType;
  }

  collectResourceInfo() {
    // {type: buffer, count: 10, usedBytes: 1024, output: ''}
    let count = 0;
    let usedBytes = 0;
    let log = '';
    for (const [key, val] of this.#mAvailablePool) {
      if (val) {
        const poolMap = val.getPool();
        for (const [bpr, bufArray] of poolMap) {
          count += bufArray.length;
          let availableCount = 0;
          let pendingCount = 0;
          for (const buffer of bufArray) {
            usedBytes += buffer.size;
            if (buffer.mapState == 'mapped') {
              availableCount += 1;
            } else {
              pendingCount += 1;
            }
          }
          log += `[GPUBufferPool] level:${key} bpr:${bpr} threshold:${val.getPoolThreshold()} pool:{len:${
            bufArray.length
          } ava_count:${availableCount} pending_count:${pendingCount}}\n`;
        }
      }
    }

    let countInUsed = 0;
    for (const item of this.#mInUsedPool) {
      count += 1;
      usedBytes += item.size;
      countInUsed += 1;
    }

    log += `[GPUBufferPool] in_used_count:${countInUsed}\n`;
    log += `[GPUBufferPool] total: count:${count} usedBytes:${usedBytes}\n`;

    this.#mResInfo.type = this.#mResType;
    this.#mResInfo.count = count;
    this.#mResInfo.usedBytes = usedBytes;
    this.#mResInfo.output = log;

    return this.#mResInfo;
  }

  /**
   * The occupancy level is evaluated and start to release some GPU resources by the level.
   *
   * @param {*} level an occupancy level helps to decide how to release resources
   */
  onOccupancyLevelEvaluated(level) {
    add_monitor(`[GPUBufferPool] onOccupancyLevelEvaluated() level:${level}`);
    console.log(`[GPUBufferPool] onOccupancyLevelEvaluated() level:${level}`);
    this.release(level);
  }

  getInUsedPoolCount() {
    return this.#mInUsedPool.length;
  }

  #setEntryToAvailablePool(key, val, needToSort = true) {
    this.#mAvailablePool.set(key, val);

    if (needToSort) {
      let entries = Array.from(this.#mAvailablePool.entries());
      entries.sort((a, b) => a[0] - b[0]);
      this.#mAvailablePool.clear();
      entries.forEach(([key, value]) => {
        this.#mAvailablePool.set(key, value);
      });
    }
  }

  #borrowBufferFromNextLevel(bufferConfig) {
    if (!bufferConfig) {
      return null;
    }

    let level = bufferConfig.level;
    let bytesPerRow = bufferConfig.bytesPerRow;
    let requiredBufferSize = bufferConfig.size;

    const nextLevel = this.#queryNextResolutionLevel(level);
    if (nextLevel <= level) {
      return null;
    }

    if (!this.#mAvailablePool.has(nextLevel)) {
      return null;
    }

    const poolEntry = this.#mAvailablePool.get(nextLevel);
    if (!poolEntry) {
      return null;
    }

    if (!poolEntry.hasBytesPerRowAsKey(bytesPerRow)) {
      return null;
    }

    if (!poolEntry.canLendBufferCrossLevel(level, bytesPerRow)) {
      return null;
    }

    const nextLevelBufferConfig = {};
    Object.assign(nextLevelBufferConfig, bufferConfig);
    nextLevelBufferConfig.level = nextLevel;

    const buffer = poolEntry.acquire(nextLevelBufferConfig, true);
    if (buffer) {
      bufferConfig.level = nextLevel;
      bufferConfig.bytesPerRow = nextLevelBufferConfig.bytesPerRow;
      // console.log(
      //   `[GPUBufferPool] borrowBufferFromNextLevel() level:${level} nextLevel:${nextLevel} bpr:${bytesPerRow} a buffer is acquired!`
      // );
    }

    return buffer;
  }
}

export default GPUBufferPool;

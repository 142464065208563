import RendererProvider from './RendererProvider';
import RendererController from './RendererController';
import RenderDisplayManager from './RendererDisplayManager';
import WebGPUResManager from './WebGPUResManager';

/**
 * RenderManager is the outer container to manage its components, here
 * are 4 most important components:
 * 1. RendererProvider
 * 2. RenderDisplayManager
 * 3. RendererController
 * 4. GPUResManager
 *
 * You can easily get these components from it.
 */
class RenderManager {
  #mLabel = '';
  #mRendererProvider = new RendererProvider();
  #mRenderDisplayManager = new RenderDisplayManager();
  #mRendererController = new RendererController();
  #mWebGPUResMgr = new WebGPUResManager();
  #supportLoseContext = false;

  constructor(label) {
    this.#mLabel = label;
    this.#mWebGPUResMgr.addRendererProviderModule(this.#mRendererProvider);
    this.#mWebGPUResMgr.setLabel(this.#mLabel);
  }

  async evalRendererType(webgpuParams) {
    const rendererType = await this.#mRendererProvider.evaluate(webgpuParams);
    console.log(`[RenderManager] rendererType is ${rendererType}`);
  }

  getVideoRenderDisplay(
    canvas,
    canvasID,
    threadNumber,
    logErrorFn,
    canvasToLocalDisplay = null
  ) {
    const rendererType = this.#mRendererProvider.getRendererType();
    const renderer = this.#mRendererProvider.acquireRenderer(
      canvas,
      this.#mWebGPUResMgr
    );
    return this.#mRenderDisplayManager.getVideoRenderDisplay(
      rendererType,
      canvas,
      canvasID,
      threadNumber,
      logErrorFn,
      renderer,
      canvasToLocalDisplay
    );
  }

  createWebGLVideoRenderDisplay(canvas, canvasID, index, args = null) {
    if (this.#mRendererProvider.isWebGL2RendererType()) {
      return this.#mRenderDisplayManager
        .getWebGL2RenderDisplayMgr()
        .createVideoRenderDisplay(canvas, canvasID, index, null, args);
    } else if (this.#mRendererProvider.isWebGLRendererType()) {
      return this.#mRenderDisplayManager
        .getWebGLRenderDisplayMgr()
        .createVideoRenderDisplay(canvas, canvasID, index, null, args);
    }

    return null;
  }

  createVideoRenderDisplay(canvas, canvasID, index, args = null) {
    const rendererType = this.#mRendererProvider.getRendererType();
    const renderer = this.#mRendererProvider.acquireRenderer(
      canvas,
      this.#mWebGPUResMgr
    );
    return this.#mRenderDisplayManager.createVideoRenderDisplay(
      rendererType,
      canvas,
      canvasID,
      index,
      renderer,
      args
    );
  }

  getSharingRenderDisplay(canvas, canvasID, args) {
    const rendererType = this.#mRendererProvider.getRendererType();
    const renderer = this.#mRendererProvider.acquireRenderer(
      canvas,
      this.#mWebGPUResMgr,
      true
    );

    // add a field of 'clearCache' to args for judging whether need to clear the cache.
    // this will help to resolve the no rAF issue.
    if (!args) {
      args = {};
    }
    args.clearCache = true;

    return this.#mRenderDisplayManager.getSharingRenderDisplay(
      rendererType,
      canvas,
      canvasID,
      renderer,
      args
    );
  }

  recycleRenderDisplay(canvas, renderDisplay, clearCanvas) {
    const rendererType = this.#mRendererProvider.getRendererType();
    this.#mRenderDisplayManager.recycleRenderDisplay(
      rendererType,
      canvas,
      renderDisplay,
      this.#mWebGPUResMgr,
      clearCanvas
    );
  }

  /**
   * Notify RendererController to dispatch rendering commands to backend renderer.
   *
   * By setting parameter `serveFor`, you can specify rendering for video part or sharing part.
   * Whichever video or sharing is selected, all canvases and render displays will be collected and
   * dispatched to the backend renderer.
   *
   * @param {*} serveFor rendering on video or sharing
   */
  renderFor(serveFor) {
    if (this.#mRendererProvider.isWebGPURendererType()) {
      const rendererType = this.#mRendererProvider.getRendererType();
      const inUseRenderDisplays =
        this.#mRenderDisplayManager.collectInUseRenderDisplays(
          rendererType,
          serveFor
        );
      if (inUseRenderDisplays) {
        inUseRenderDisplays.forEach((rdListItem) => {
          const renderer = this.#mRendererProvider.acquireRenderer(
            rdListItem.canvas,
            this.#mWebGPUResMgr
          );
          this.#mRendererController.render(renderer, rdListItem.renderDisplays);
        });
      }
    }
  }

  /**
   * Notify RendererController to dispatch rendering commands to backend renderer.
   *
   * Unlike the `renderFor(serveFor)` function, when you only want to render all the render displays
   * on a specific single canvas, call this function. By passing a render display, the target canvas
   * and attached render displays will be collected and then dispatched to the backend renderer.
   *
   * @param {*} renderDisplay use it to query the target canvas
   */
  renderWith(renderDisplay) {
    if (this.#mRendererProvider.isWebGPURendererType()) {
      const canvas = renderDisplay.getAttachedCanvas();
      if (canvas) {
        const renderer = this.#mRendererProvider.acquireRenderer(
          canvas,
          this.#mWebGPUResMgr
        );

        const renderDisplays = [];
        renderDisplays.push(renderDisplay);

        this.#mRendererController.render(renderer, renderDisplays);
      }
    }
  }

  getRenderDisplayMap(serveFor) {
    const rendererType = this.#mRendererProvider.getRendererType();
    return this.#mRenderDisplayManager.getRenderDisplayMap(
      rendererType,
      serveFor
    );
  }

  onRestoredFromContextLost(
    canvasId,
    canvas,
    oldCanvas,
    threadNumber,
    logErrorFn,
    canvasToLocalDisplay = null
  ) {
    if (
      this.#mRendererProvider.isWebGLRendererType() ||
      this.#mRendererProvider.isWebGL2RendererType()
    ) {
      return this.#mRenderDisplayManager.onRestoredFromContextLost(
        canvasId,
        canvas,
        oldCanvas,
        threadNumber,
        logErrorFn,
        canvasToLocalDisplay
      );
    }

    return null;
  }

  destroyUnusedVideoFrame(videoFrame) {
    if (typeof VideoFrame != 'undefined' && videoFrame instanceof VideoFrame) {
      if (this.#mRendererProvider.isWebGPURendererType()) {
        // only provide this ability for webgpu renderer
        videoFrame.close();
      }
    }
  }

  getRendererProvider() {
    return this.#mRendererProvider;
  }

  getRenderDisplayManager() {
    return this.#mRenderDisplayManager;
  }

  getWebGPUResMgr() {
    return this.#mWebGPUResMgr;
  }

  /**
   * Notify each module to cleanup their own attached resources.
   *
   * @param {*} canvas target will be cleared
   * @param {*} display target will be cleared
   */
  cleanup(canvas, display) {
    if (this.#mRendererProvider) {
      this.#mRendererProvider.cleanup();
    }

    if (this.#mRenderDisplayManager) {
      this.#mRenderDisplayManager.cleanup(
        canvas,
        display,
        this.#mWebGPUResMgr,
        this.#supportLoseContext
      );
    }

    if (this.#mWebGPUResMgr) {
      this.#mWebGPUResMgr.cleanup();
    }
  }

  clearOffscreenCanvas(canvas) {
    if (this.#mRenderDisplayManager) {
      this.#mRenderDisplayManager.cleanupByCanvas(
        canvas,
        this.#mWebGPUResMgr,
        this.#supportLoseContext
      );
    }
  }

  setSupportLoseContext(support) {
    this.#supportLoseContext = support;
  }

  getSupportLoseContext() {
    return this.#supportLoseContext;
  }
}

export default RenderManager;

import Queue from '../common/queue';
import globalTracingLogger from '../common/globalTracingLogger';
import util from '../common/util';
import { VideoInit, SharingInit, AudioInit } from './MediaInit';
import Log from '../common/log';
import { WORKER_TYPE, AudioStatus } from '../common/enums/CommonEnums';

const log = Log('sdk.variables');
function Variables() {
  this.crashCount = 0;
  this.crashLastTime = 0;
  this.AEW = null;
  this.ADW = null;
  this.AEWF = null;
  this.ADWF = null;
  this.VE = null;
  this.VD = null;
  this.SE = null;
  this.SD = null;
}

Variables.prototype.initDB = function () {
  let dbObject = {};
  let self = this;
  let request;
  dbObject.init = function (params) {
    this.db_name = params.db_name;
    this.db_version = params.db_version;
    this.db_store_name = params.db_store_name;
    if (!indexedDB) {
      self.indexDbObject = null;
      return;
    }
    try {
      request = indexedDB.open(this.db_name, this.db_version);
    } catch (e) {
      globalTracingLogger.error('Error opening IndexedDB', e);
      self.indexDbObject = null;
      return;
    }

    request.onerror = function (event) {
      self.indexDbObject = null;
    };
    request.onupgradeneeded = function (event) {
      this.db = event.target.result;
      this.db.createObjectStore(dbObject.db_store_name);
    };

    request.onsuccess = function (event) {
      dbObject.db = event.target.result;
      self.openIndexFlag = true;
      self.indexDbObject.select('delay');
    };
  };

  dbObject.put = function (params, key) {
    try {
      var transaction = dbObject.db.transaction(
        dbObject.db_store_name,
        'readwrite'
      );
      var store = transaction.objectStore(dbObject.db_store_name);
      var request = store.put(params, key);
      request.onsuccess = function () {
        //  log("Set Delay Successfully in indexdb: " + params);
      };
      request.onerror = function (event) {
        globalTracingLogger.error('Set Delay failed!', event);
      };
    } catch (e) {
      globalTracingLogger.error('IndexDb put Failed!', e);
    }
  };

  dbObject.delete = function (id) {
    // dbObject.db.transaction.objectStore is not a function
    request = dbObject.db
      .transaction(dbObject.db_store_name, 'readwrite')
      .objectStore(dbObject.db_store_name)
      .delete(id);
    request.onsuccess = function () {
      log('Delete the key:' + id);
    };
  };

  dbObject.select = function (key) {
    try {
      var transaction = dbObject.db.transaction(
        dbObject.db_store_name,
        'readwrite'
      );
      var store = transaction.objectStore(dbObject.db_store_name);
      if (key) var request = store.get(key);
      else var request = store.getAll();
      request.onsuccess = function () {
        self.audioDelay = request.result;
      };
      request.onerror = function () {
        self.audioDelay = 0;
      };
    } catch (e) {
      globalTracingLogger.error('IndexDb Select Failed', e);
    }
  };

  dbObject.clear = function () {
    var request = dbObject.db
      .transaction(dbObject.db_store_name, 'readwrite')
      .objectStore(dbObject.db_store_name)
      .clear();
    request.onsuccess = function () {
      log('Clear the IndexDb Successfully');
    };
  };
  dbObject.close = function () {
    if (dbObject.db) {
      dbObject.db.close();
    }
  };
  this.indexDbObject = dbObject;
  this.indexDbObject.init({
    db_name: 'AEC',
    db_version: 1,
    db_store_name: 'delay',
  });
};
/**
 * send message only if _Notify_APPUI is existed
 * @constructor
 */
Variables.prototype.Notify_APPUI_SAFE = function (instance, ...args) {
  // the first argument may be current instance of mediasdk
  // check the first argument, the instance must be current.
  if (typeof instance === 'number' || typeof instance === 'string') {
    args = arguments;
  } else {
    if (instance !== this.instance) {
      return;
    }
  }
  if (this._Notify_APPUI) {
    log('Notify_APPUI_SAFE', args);
    this._Notify_APPUI.apply(this, args);
    this._callbackList.forEach((c) => c.apply(this, args));
  }
};

// the logic is same, just keep the old code runs.
Variables.prototype.Notify_APPUI = Variables.prototype.Notify_APPUI_SAFE;

/**
 * Only can send message when rwg send back 4098 signal, which means join meeting successfully
 *
 * @param jsEvent {String|number|Object.<string, any>}
 * @param message {Object.<string, any>}
 */
Variables.prototype.queueMessageToRwg = function (jsEvent, message) {
  this.rwgMessageList.push({
    jsEvent,
    message,
  });
};

/**
 * Sending all the queue messages to rwg when join meeting successfully
 */
Variables.prototype.clearMessageToRwg = function () {
  this.rwgConnectSuccess = true;
  if (this.rwgMessageList.length > 0 && (this.rwgAgent || this._Notify_APPUI)) {
    this.rwgMessageList.forEach(({ jsEvent, message }) => {
      this.sendMessageToRwg(jsEvent, message, false, false);
    });
    this.rwgMessageList = [];
  }
};

/**
 * Sending all the queue messages to rwg when join meeting successfully
 */
Variables.prototype.destroyQueueMessageToRwg = function () {
  this.rwgConnectSuccess = false;
  this.rwgMessageList = [];
};

/**
 * send message to RWG by {@link Variables.rwgAgent}
 * If jsEvent is {Object.<string, any>} and message is not set, then set jsEvent value to message.
 *
 * @param jsEvent {String|number|Object.<string, any>}
 * @param message {Object.<string, any>}
 */
Variables.prototype.sendMessageToRwg = function (
  jsEvent,
  message,
  shouldQueue = true,
  shouldCheckQueue = true
) {
  if (
    (!this.rwgConnectSuccess || (!this.rwgAgent && !this._Notify_APPUI)) &&
    shouldQueue
  ) {
    this.queueMessageToRwg(jsEvent, message);
    return;
  }
  /** if ui set rwgAgent or _Notify_APPUI later then call "clearMessageToRwg", need to re-check */
  if (this.rwgConnectSuccess && shouldCheckQueue) {
    this.clearMessageToRwg();
  }
  try {
    if (this.rwgAgent) {
      this.rwgAgent.sendMessageToRwg(message);
    } else {
      this.Notify_APPUI_SAFE(jsEvent, message);
    }
  } catch (e) {
    globalTracingLogger.error('Error sending message to RWG', e);
  }
};

Variables.prototype.reinit = function (instance) {
  this.instance = instance;
  this.SPECIAL_ID = 0;
  this._callbackList = [];
  /**
   * do not use _Notify_APPUI anymore, instead use {@link Notify_APPUI_SAFE} or {@link Notify_APPUI}
   */
  this._Notify_APPUI = null;
  this.localAudioDecMGR = null;
  this.localVideoDecMGR = null;
  this.localAudioEncMGR = null;
  this.localVideoEncMGR = null;
  this.localSharingDecMGR = null;
  this.localMouseDecMGR = null;
  this.localSharingEncMGR = null;
  this.localAudioPara = null;
  this.localVideoPara = {};
  this.localSharingPara = null;
  this.Audio_WebSocket_Ip_Address = null;
  this.Audio_Web_Transport_Ip_Address = null;
  this.Video_WebSocket_Ip_Address = null;
  this.Video_Web_Transport_Ip_Address = null;
  this.Sharing_WebSocket_Ip_Address = null;
  this.mediaSDKHandle = null;
  this.audio_pcm_queue = new Queue();
  this.int16Array = null;
  // status
  this.isInitialFailed = false;
  //Is related WASM initial for web APP?
  this.isAudioEncodeWASMOK = false;
  this.isAudioDecodeWASMOK = false;
  this.isVideoEncodeWASMOK = false;
  this.isVideoDecodeWASMOK = false;
  this.isSharingDecodeWASMOK = false;
  this.isSharingEncodeWASMOK = false;
  this.audioEncWorkerPath = '';
  this.audioDecWorkerPath = '';
  this.videoDecWorkerPath = '';
  this.videoEncWorkerPath = '';
  this.sharingDecWorkerPath = '';
  this.sharingEncWorkerPath = '';
  this.isAudioEncodePostStart = false;
  this.isAudioDecodePostStart = false;
  this.isVideoDecodePostStart = false;
  this.isVideoEncodePostStart = false;
  this.isSharingDecodePostStart = false;
  this.isSharingEncodePostStart = false;
  this.isAudioEncodeThreadStart = false;
  this.isAudioDecodeThreadStart = false;
  this.isVideoDecodeThreadStart = false;
  this.isSharingDecodeThreadStart = false;
  this.isVideoEncodeThreadStart = false;
  this.isSharingEncodeThreadStart = false;
  this.isAudioPlayWork = false;
  this.isVideoPlayWork = false;
  this.isSharingPlayWork = false;
  this.shareBufferSampleNumb = 30;
  this.audioWasmInfo = null;

  // Add One When WebWork do success
  this.initialSuccessNumb = 0;
  this.TotalWaitEvent = 0;
  this.audioPostInterval = null;
  this.sharedBuffer = null;
  this.decoderinworklet = null;
  this.decoderinworkletOP = null;
  this.chromeWideAEC = null;
  this.shareSystemAudio = false;

  this.indexDbObject = null;
  this.audioDelay = 0;
  this.openIndexFlag = false;

  this.audioBufferSize = util.browser.isFirefox ? 30 : 15;

  this.monitorEncodeVideo = null;
  this.monitorDecodeVideo = null;
  this.monitorEncodeSharing = null;
  this.monitorDecodeSharing = null;
  this.monitorEncodeAudio = null;
  this.monitorDecodeAudio = null;
  this.monitorVideoCapture = null;
  this.monitorVideoReadyCaptureWidth = 0;
  this.monitorVideoReadyCaptureHeight = 0;
  this.monitorIntervalHandle = null;
  this.monitorVideoUserID = null;
  this.monitorSharingUserID = null;
  this.monitorSharingEncodeAPPInfo = null;
  this.monitorVideoDecodeAPPInfo = null;
  this.monitorVideoEncodeAPPInfo = null;
  this.monitorCid = null;
  this.e2eencrypt = false;

  this.AudioNode = null;
  this.SharingAudioNode = null;
  this.CurrentSSRC = 0;
  this.CurrentSSRCTime = 0;
  this.audioPlayTime = 0;

  this.videoDecResponseText = null;
  this.videoEncResponseText = null;
  this.sharingDecodeResponse = null;
  this.sharingEncodeResponse = null;

  this.sharingDecInitInstance = new SharingInit(WORKER_TYPE.SHARING_DECODE);
  this.sharingEncInitInstance = new SharingInit(WORKER_TYPE.SHARING_ENCODE);
  this.videoDecInitInstance = new VideoInit(WORKER_TYPE.VIDEO_DECODE);
  this.videoInitInstance = new VideoInit(WORKER_TYPE.VIDEO_ENCODE);
  this.audioDecInitInstance = new AudioInit(WORKER_TYPE.AUDIO_DECODE);
  this.audioEncodeInitInstance = new AudioInit(WORKER_TYPE.AUDIO_ENCODE);

  this.audioDecodeResponse = null;
  this.audioEncodeResponse = null;
  this.ivObj = {};
  this.userNodeList = [];
  this.rwgAgent = null;

  this.ComputerAudioStatus = AudioStatus.ComputerAudio_Null;
  this.DesktopAudioStatus = AudioStatus.DesktopAudio_Null;
  this.initDB();

  this.resourceManager = null;
  this.vbarraybuffer = null;
  this.vbbin = null;
  this.vbjson = null;
  this.tfjsurl = null;

  this.afnbin = null;
  this.afnjson = null;

  this.basebin = null;
  this.basejson = null;
  this.interpretationMessage = new Queue();
  this.workletWasmInitSuccess = false;

  this.videoEncodeInitSuccess = null;
  this.vbPreloadSuccess = null;

  this.enableAduioBridge = false;

  this.audioMode = -1;
  this.enableEchoDetection = 0;
  /** status for preview mode */
  this.isPreviewMode = {
    audioEncode: false,
    audioDecode: false,
    videoEncode: false,
    videoDecode: false,
  };

  /** receive 4098 signal successfully */
  this.rwgConnectSuccess = false;
  /** list for queue sending rwg message before connected to rwg successfully */
  this.rwgMessageList = [];

  /** local user self ssrc */
  this.localSsrc = 0;
};

let vInstance = new Variables();

export default vInstance;

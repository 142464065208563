import H264bsdCanvas from '../WebGLCanvas';
import WebGL2Canvas from './WebGL2Canvas';
import WebGPURenderDisplay from './WebGPURenderDisplay';
import RenderDisplayPool from './RenderDisplayPool';
import * as RenderConst from './RenderConst';
import { VIDEO_INVALID } from '../../worker/common/consts';
import { create_webgl_context_failed_monitor } from '../../worker/common/common';

/**
 * RenderDisplayManager helps you to create and manage render displays
 * no matter which the backend renderer is used, WebGPU or WebGL. It provides
 * the standard and public APIs for requesting or managing render displays,
 * this eliminates the differences between WebGL and WebGPU render displays.
 */
class RenderDisplayManager {
  #webglRenderDisplayMgr = null;
  #webgl2RenderDisplayMgr = null;
  #wgpuRenderDisplayMgr = null;

  constructor() {}

  getWebGLRenderDisplayMgr() {
    if (!this.#webglRenderDisplayMgr) {
      this.#webglRenderDisplayMgr = new WebGLRenderDisplayMgr();
    }

    return this.#webglRenderDisplayMgr;
  }

  getWebGL2RenderDisplayMgr() {
    if (!this.#webgl2RenderDisplayMgr) {
      this.#webgl2RenderDisplayMgr = new WebGL2RenderDisplayMgr();
    }

    return this.#webgl2RenderDisplayMgr;
  }

  getWebGPURenderDisplayMgr() {
    if (!this.#wgpuRenderDisplayMgr) {
      this.#wgpuRenderDisplayMgr = new WebGPURenderDisplayMgr();
    }

    return this.#wgpuRenderDisplayMgr;
  }

  getVideoRenderDisplay(
    rendererType,
    canvas,
    canvasID,
    threadNumber,
    logErrorFn,
    renderer = null,
    canvasToLocalDisplay = null
  ) {
    let display = null;
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGL) {
      if (!this.#webglRenderDisplayMgr) {
        this.#webglRenderDisplayMgr = new WebGLRenderDisplayMgr();
      }

      display = this.#webglRenderDisplayMgr.getVideoRenderDisplay(
        canvas,
        canvasID,
        threadNumber,
        logErrorFn,
        canvasToLocalDisplay
      );
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGL_2) {
      if (!this.#webgl2RenderDisplayMgr) {
        this.#webgl2RenderDisplayMgr = new WebGL2RenderDisplayMgr();
      }

      display = this.#webgl2RenderDisplayMgr.getVideoRenderDisplay(
        canvas,
        canvasID,
        threadNumber,
        logErrorFn,
        canvasToLocalDisplay
      );
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      if (!this.#wgpuRenderDisplayMgr) {
        this.#wgpuRenderDisplayMgr = new WebGPURenderDisplayMgr();
      }

      display = this.#wgpuRenderDisplayMgr.getVideoRenderDisplay(
        canvas,
        canvasID,
        threadNumber,
        logErrorFn
      );
      display.addRenderer(renderer);
      display.attachCanvas(canvas);
    }

    return display;
  }

  getSharingRenderDisplay(
    rendererType,
    canvas,
    canvasID,
    renderer = null,
    args = null
  ) {
    let display = null;
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGL) {
      if (!this.#webglRenderDisplayMgr) {
        this.#webglRenderDisplayMgr = new WebGLRenderDisplayMgr();
      }

      display = this.#webglRenderDisplayMgr.getSharingRenderDisplay(
        canvas,
        canvasID,
        args
      );
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGL_2) {
      if (!this.#webgl2RenderDisplayMgr) {
        this.#webgl2RenderDisplayMgr = new WebGL2RenderDisplayMgr();
      }

      display = this.#webgl2RenderDisplayMgr.getSharingRenderDisplay(
        canvas,
        canvasID,
        args
      );
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      if (!this.#wgpuRenderDisplayMgr) {
        this.#wgpuRenderDisplayMgr = new WebGPURenderDisplayMgr();
      }

      display = this.#wgpuRenderDisplayMgr.getSharingRenderDisplay(
        canvas,
        canvasID,
        args
      );
      display.addRenderer(renderer);
      display.attachCanvas(canvas);
    }

    return display;
  }

  createVideoRenderDisplay(
    rendererType,
    canvas,
    canvasID,
    index,
    renderer = null,
    args = null
  ) {
    let display = null;
    if (rendererType == RenderConst.RENDERER_TYPE.WEBGL) {
      if (!this.#webglRenderDisplayMgr) {
        this.#webglRenderDisplayMgr = new WebGLRenderDisplayMgr();
      }
      display = this.#webglRenderDisplayMgr.createVideoRenderDisplay(
        canvas,
        canvasID,
        index,
        renderer,
        args
      );
    } else if (rendererType == RenderConst.RENDERER_TYPE.WEBGL_2) {
      if (!this.#webgl2RenderDisplayMgr) {
        this.#webgl2RenderDisplayMgr = new WebGL2RenderDisplayMgr();
      }

      display = this.#webgl2RenderDisplayMgr.createVideoRenderDisplay(
        canvas,
        canvasID,
        index,
        renderer,
        args
      );
    } else if (rendererType == RenderConst.RENDERER_TYPE.WEBGPU) {
      if (!this.#wgpuRenderDisplayMgr) {
        this.#wgpuRenderDisplayMgr = new WebGPURenderDisplayMgr();
      }
      display = this.#wgpuRenderDisplayMgr.createVideoRenderDisplay(
        canvas,
        canvasID,
        index,
        renderer,
        args
      );
      display.addRenderer(renderer);
      display.attachCanvas(canvas);
    }

    return display;
  }

  recycleRenderDisplay(
    rendererType,
    canvas,
    renderDisplay,
    webgpuResMgr,
    clearCanvas = true
  ) {
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      if (this.#wgpuRenderDisplayMgr) {
        this.#wgpuRenderDisplayMgr.recycleRenderDisplay(
          renderDisplay,
          webgpuResMgr,
          clearCanvas
        );
      }
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGL) {
      if (this.#webglRenderDisplayMgr) {
        this.#webglRenderDisplayMgr.recycleRenderDisplay(
          canvas,
          renderDisplay,
          clearCanvas
        );
      }
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGL_2) {
      if (this.#webgl2RenderDisplayMgr) {
        this.#webgl2RenderDisplayMgr.recycleRenderDisplay(
          canvas,
          renderDisplay,
          clearCanvas
        );
      }
    }
  }

  collectInUseRenderDisplays(rendererType, serveFor) {
    let matchedRenderDisplays = null;
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      if (this.#wgpuRenderDisplayMgr) {
        matchedRenderDisplays =
          this.#wgpuRenderDisplayMgr.collectInUseRenderDisplays(serveFor);
      }
    }

    return matchedRenderDisplays;
  }

  collectInUseRenderDisplaysByCanvas(rendererType, canvas, serveFor) {
    let matchedRenderDisplays = null;
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      if (this.#wgpuRenderDisplayMgr) {
        matchedRenderDisplays =
          this.#wgpuRenderDisplayMgr.collectInUseRenderDisplaysByCanvas(
            canvas,
            serveFor
          );
      }
    }

    return matchedRenderDisplays;
  }

  getRenderDisplayMap(rendererType, serveFor) {
    if (rendererType === RenderConst.RENDERER_TYPE.WEBGL) {
      if (this.#webglRenderDisplayMgr) {
        return this.#webglRenderDisplayMgr.getRenderDisplayMap();
      }
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGPU) {
      if (this.#wgpuRenderDisplayMgr) {
        return this.#wgpuRenderDisplayMgr.getRenderDisplayMap(serveFor);
      }
    } else if (rendererType === RenderConst.RENDERER_TYPE.WEBGL_2) {
      if (this.#webgl2RenderDisplayMgr) {
        return this.#webgl2RenderDisplayMgr.getRenderDisplayMap(serveFor);
      }
    }

    return null;
  }

  onRestoredFromContextLost(
    canvasId,
    canvas,
    oldCanvas,
    threadNumber,
    logErrorFn,
    canvasToLocalDisplay = null
  ) {
    if (this.#webglRenderDisplayMgr) {
      return this.#webglRenderDisplayMgr.onRestoredFromContextLost(
        canvasId,
        canvas,
        oldCanvas,
        threadNumber,
        logErrorFn,
        canvasToLocalDisplay
      );
    }

    if (this.#webgl2RenderDisplayMgr) {
      return this.#webgl2RenderDisplayMgr.onRestoredFromContextLost(
        canvasId,
        canvas,
        oldCanvas,
        threadNumber,
        logErrorFn,
        canvasToLocalDisplay
      );
    }

    return null;
  }

  cleanup(canvas, display, resMgr, loseContext = false) {
    if (this.#webglRenderDisplayMgr !== null) {
      this.#webglRenderDisplayMgr.cleanup(canvas, display, loseContext);
    }

    if (this.#webgl2RenderDisplayMgr) {
      this.#webgl2RenderDisplayMgr.cleanup(canvas, display, loseContext);
    }

    if (this.#wgpuRenderDisplayMgr !== null) {
      this.#wgpuRenderDisplayMgr.cleanup(display, resMgr);
    }
  }

  cleanupByCanvas(canvas, resMgr, loseContext = false) {
    if (this.#webglRenderDisplayMgr !== null) {
      this.#webglRenderDisplayMgr.cleanupByCanvas(canvas, loseContext);
    }

    if (this.#webgl2RenderDisplayMgr !== null) {
      this.#webgl2RenderDisplayMgr.cleanupByCanvas(canvas, loseContext);
    }

    if (this.#wgpuRenderDisplayMgr !== null) {
      this.#wgpuRenderDisplayMgr.cleanupByCanvas(canvas, resMgr);
    }
  }
}

class IRenderDisplayManager {
  getVideoRenderDisplay(canvas, canvasID, threadNumber, logErrorFn) {
    throw new Error(
      'getVideoRenderDisplay() should be implemented by subclass.'
    );
  }

  getSharingRenderDisplay(canvas, canvasID, args) {
    throw new Error(
      'getSharingRenderDisplay() should be implemented by subclass.'
    );
  }

  createVideoRenderDisplay(
    canvas,
    canvasID,
    index,
    renderer = null,
    args = null
  ) {
    throw new Error(
      'createVideoRenderDisplay() should be implemented by subclass.'
    );
  }
}

class WebGLRenderDisplayMgr extends IRenderDisplayManager {
  #renderDisplayMap = new Map();

  constructor() {
    super();
  }

  createVideoRenderDisplay(
    canvas,
    canvasID,
    index,
    renderer = null,
    args = null
  ) {
    let forceNoGL = null;
    let contextOptions = null;
    let webGLResources = null;
    let initMask = false;
    if (args) {
      forceNoGL = args.forceNoGL;
      contextOptions = args.contextOptions;
      webGLResources = args.webGLResources;
      initMask = args.initMask;
    }

    return new H264bsdCanvas(
      canvas,
      canvasID,
      index,
      forceNoGL,
      contextOptions,
      webGLResources,
      initMask
    );
  }

  getVideoRenderDisplay(
    canvas,
    canvasID,
    threadNumber,
    logErrorFn,
    canvasToLocalDisplay = null
  ) {
    let renderArray = this.#renderDisplayMap.get(canvas);
    if (!renderArray) {
      let unusedArray = [];
      let usedArray = [];
      renderArray = [unusedArray, usedArray];
      this.#renderDisplayMap.set(canvas, renderArray);

      let localDisplay = new H264bsdCanvas(
        canvas,
        canvasID,
        0,
        undefined,
        undefined
      );
      localDisplay.setMultiView(true);
      if (canvasToLocalDisplay) {
        canvasToLocalDisplay.set(canvas, localDisplay);
      }

      let index = 1;
      for (; index <= threadNumber; index++) {
        const rDisplay = new H264bsdCanvas(
          canvas,
          canvasID,
          index,
          undefined,
          undefined,
          {
            program: localDisplay.shaderProgram,
            contextgl: localDisplay.contextGL,
            vBuffer: localDisplay.vertexPosBuffer,
            tBuffer: localDisplay.texturePosBuffer,
            waterMarkTextureRef: localDisplay.waterMarkTextureRef,
            repeatedWaterMarkTextureRef:
              localDisplay.repeatedWaterMarkTextureRef,
          }
        );
        rDisplay.setMultiView(true);
        unusedArray.push(rDisplay);
      }
    }

    let canvasRenderArray = this.#renderDisplayMap.get(canvas);
    let unusedRenderArray = canvasRenderArray[0];
    let usedRenderArray = canvasRenderArray[1];
    let display;

    if (canvasRenderArray) {
      if (unusedRenderArray[0]) {
        display = unusedRenderArray.shift();
      }

      usedRenderArray.push(display);
    }

    if (!display) {
      const logCRA = canvasRenderArray
        ? `${canvasRenderArray.length}`
        : 'undefined';
      const logURA = unusedRenderArray
        ? `${unusedRenderArray.length}`
        : 'undefined';

      logErrorFn(
        `No Display obtained from VideoRender.Get_Display. canvasRenderArray:${logCRA} unusedRenderArray:${logURA}`
      );
    }

    return display;
  }

  getSharingRenderDisplay(canvas, canvasID, args) {
    const sharingRenderDisplay = new H264bsdCanvas(
      canvas,
      canvasID,
      0,
      undefined,
      args.contextOptions
    );

    return sharingRenderDisplay;
  }

  recycleRenderDisplay(canvas, renderDisplay, clearCanvas) {
    renderDisplay.setWatermarkFlag(0);
    renderDisplay.setVideoMode(VIDEO_INVALID);
    renderDisplay.clear(clearCanvas);

    let canvasRenderArray = this.#renderDisplayMap.get(canvas);
    if (canvasRenderArray) {
      let unusedRenderArray = canvasRenderArray[0];
      let usedRenderArray = canvasRenderArray[1];
      if (usedRenderArray) {
        usedRenderArray.some(function (handle, idx) {
          if (handle === renderDisplay) {
            usedRenderArray.splice(idx, 1);
            return true;
          }
        });
      }
      unusedRenderArray.push(renderDisplay);
    }
  }

  onRestoredFromContextLost(
    canvasId,
    canvas,
    oldCanvas,
    threadNumber,
    logErrorFn,
    canvasToLocalDisplay = null
  ) {
    let newRenderDisplayMap = new Map();
    let localRenderDisplay = new H264bsdCanvas(
      canvas,
      canvasId,
      0,
      undefined,
      undefined
    );
    if (!localRenderDisplay.contextGL) {
      create_webgl_context_failed_monitor(canvasId);
    }

    localRenderDisplay.setMultiView(true);

    for (let index = 1; index <= threadNumber; index++) {
      const renderDisplay = new H264bsdCanvas(
        canvas,
        canvasId,
        index,
        undefined,
        undefined,
        {
          program: localRenderDisplay.shaderProgram,
          contextgl: localRenderDisplay.contextGL,
          vBuffer: localRenderDisplay.vertexPosBuffer,
          tBuffer: localRenderDisplay.texturePosBuffer,
          waterMarkTextureRef: localRenderDisplay.waterMarkTextureRef,
          repeatedWaterMarkTextureRef:
            localRenderDisplay.repeatedWaterMarkTextureRef,
        }
      );

      renderDisplay.setMultiView(true);
      newRenderDisplayMap.set(index, renderDisplay);
    }

    let canvasRenderArray = this.#renderDisplayMap.get(oldCanvas);
    if (!canvasRenderArray || canvasRenderArray.length < 2) {
      logErrorFn(
        `canvasRenderArray:${canvasRenderArray}, length:${canvasRenderArray?.length}`
      );
    }
    for (let i = 0; i < canvasRenderArray?.length; i++) {
      canvasRenderArray[i] = canvasRenderArray[i].map((renderDisplay) =>
        newRenderDisplayMap.get(renderDisplay.getTextureIndex())
      );
    }

    if (oldCanvas !== canvas) {
      this.#renderDisplayMap.delete(oldCanvas);
      this.#renderDisplayMap.set(canvas, canvasRenderArray);
      if (canvasToLocalDisplay) {
        canvasToLocalDisplay.delete(oldCanvas);
        canvasToLocalDisplay.set(canvas, localRenderDisplay);
      }
    }

    return newRenderDisplayMap;
  }

  getRenderDisplayMap() {
    return this.#renderDisplayMap;
  }

  cleanup(canvas, display, loseContext = false) {
    if (process.env.NODE_ENV === 'development') {
      console.log('WebGLRenderDisplayMgr.cleanup');
    }
    if (display) {
      display.cleanup(null, loseContext);
    }
    // call WebglCanvas.cleanup()
    for (const [key, value] of this.#renderDisplayMap) {
      const unusedArray = value[0];
      const usedArray = value[1];
      for (const element of unusedArray) {
        element.cleanup();
      }
      for (const element of usedArray) {
        element.cleanup();
      }
    }
    this.#renderDisplayMap = new Map();
  }

  cleanupByCanvas(canvas, loseContext = false) {
    let renderarray = this.#renderDisplayMap.get(canvas);
    if (renderarray) {
      let canvasrenderarray = this.#renderDisplayMap.get(canvas);
      if (canvasrenderarray) {
        let unusedrenderarray = canvasrenderarray[0];
        let usedrenderarray = canvasrenderarray[1];
        usedrenderarray.forEach(function (display) {
          display.cleanup(null, loseContext);
        });
        unusedrenderarray.forEach(function (display) {
          display.cleanup(null, loseContext);
        });
        unusedrenderarray = [];
        usedrenderarray = [];
        this.#renderDisplayMap.delete(canvas);
      }
    }
  }
}

class WebGL2RenderDisplayMgr extends IRenderDisplayManager {
  #renderDisplayMap = new Map();

  constructor() {
    super();
  }

  createVideoRenderDisplay(
    canvas,
    canvasID,
    index,
    renderer = null,
    args = null
  ) {
    let forceNoGL = null;
    let contextOptions = null;
    let webGLResources = null;
    let initMask = false;
    if (args) {
      forceNoGL = args.forceNoGL;
      contextOptions = args.contextOptions;
      webGLResources = args.webGLResources;
      initMask = args.initMask;
    }

    return new WebGL2Canvas(
      canvas,
      canvasID,
      index,
      forceNoGL,
      contextOptions,
      webGLResources,
      initMask
    );
  }

  getVideoRenderDisplay(
    canvas,
    canvasID,
    threadNumber,
    logErrorFn,
    canvasToLocalDisplay = null
  ) {
    let renderArray = this.#renderDisplayMap.get(canvas);
    if (!renderArray) {
      let unusedArray = [];
      let usedArray = [];
      renderArray = [unusedArray, usedArray];
      this.#renderDisplayMap.set(canvas, renderArray);

      let localDisplay = new WebGL2Canvas(
        canvas,
        canvasID,
        0,
        undefined,
        undefined
      );
      localDisplay.setMultiView(true);
      if (canvasToLocalDisplay) {
        canvasToLocalDisplay.set(canvas, localDisplay);
      }

      let index = 1;
      for (; index <= threadNumber; index++) {
        const rDisplay = new WebGL2Canvas(
          canvas,
          canvasID,
          index,
          undefined,
          undefined,
          {
            program: localDisplay.shaderProgram,
            contextgl: localDisplay.contextGL,
            vBuffer: localDisplay.vertexPosBuffer,
            tBuffer: localDisplay.texturePosBuffer,
            waterMarkTextureRef: localDisplay.waterMarkTextureRef,
            repeatedWaterMarkTextureRef:
              localDisplay.repeatedWaterMarkTextureRef,
          }
        );
        rDisplay.setMultiView(true);
        unusedArray.push(rDisplay);
      }
    }

    let canvasRenderArray = this.#renderDisplayMap.get(canvas);
    let unusedRenderArray = canvasRenderArray[0];
    let usedRenderArray = canvasRenderArray[1];
    let display;

    if (canvasRenderArray) {
      if (unusedRenderArray[0]) {
        display = unusedRenderArray.shift();
      }

      usedRenderArray.push(display);
    }

    if (!display) {
      const logCRA = canvasRenderArray
        ? `${canvasRenderArray.length}`
        : 'undefined';
      const logURA = unusedRenderArray
        ? `${unusedRenderArray.length}`
        : 'undefined';

      logErrorFn(
        `No Display obtained from VideoRender.Get_Display. canvasRenderArray:${logCRA} unusedRenderArray:${logURA}`
      );
    }

    return display;
  }

  getSharingRenderDisplay(canvas, canvasID, args) {
    const sharingRenderDisplay = new WebGL2Canvas(
      canvas,
      canvasID,
      0,
      undefined,
      args.contextOptions
    );

    return sharingRenderDisplay;
  }

  recycleRenderDisplay(canvas, renderDisplay, clearCanvas) {
    renderDisplay.setWatermarkFlag(0);
    renderDisplay.setVideoMode(VIDEO_INVALID);
    renderDisplay.clear(clearCanvas);

    let canvasRenderArray = this.#renderDisplayMap.get(canvas);
    if (canvasRenderArray) {
      let unusedRenderArray = canvasRenderArray[0];
      let usedRenderArray = canvasRenderArray[1];
      if (usedRenderArray) {
        usedRenderArray.some(function (handle, idx) {
          if (handle === renderDisplay) {
            usedRenderArray.splice(idx, 1);
            return true;
          }
        });
      }
      unusedRenderArray.push(renderDisplay);
    }
  }

  onRestoredFromContextLost(
    canvasId,
    canvas,
    oldCanvas,
    threadNumber,
    logErrorFn,
    canvasToLocalDisplay = null
  ) {
    let newRenderDisplayMap = new Map();
    let localRenderDisplay = new WebGL2Canvas(
      canvas,
      canvasId,
      0,
      undefined,
      undefined
    );
    if (!localRenderDisplay.contextGL) {
      create_webgl_context_failed_monitor(canvasId);
    }

    localRenderDisplay.setMultiView(true);

    for (let index = 1; index <= threadNumber; index++) {
      const renderDisplay = new WebGL2Canvas(
        canvas,
        canvasId,
        index,
        undefined,
        undefined,
        {
          program: localRenderDisplay.shaderProgram,
          contextgl: localRenderDisplay.contextGL,
          vBuffer: localRenderDisplay.vertexPosBuffer,
          tBuffer: localRenderDisplay.texturePosBuffer,
          waterMarkTextureRef: localRenderDisplay.waterMarkTextureRef,
          repeatedWaterMarkTextureRef:
            localRenderDisplay.repeatedWaterMarkTextureRef,
        }
      );

      renderDisplay.setMultiView(true);
      newRenderDisplayMap.set(index, renderDisplay);
    }

    let canvasRenderArray = this.#renderDisplayMap.get(oldCanvas);

    if (!canvasRenderArray || canvasRenderArray.length < 2) {
      logErrorFn(
        `canvasRenderArray:${canvasRenderArray}, length:${canvasRenderArray?.length}`
      );
    }
    for (let i = 0; i < canvasRenderArray?.length; i++) {
      canvasRenderArray[i] = canvasRenderArray[i].map((renderDisplay) =>
        newRenderDisplayMap.get(renderDisplay.getTextureIndex())
      );
    }

    if (oldCanvas !== canvas) {
      this.#renderDisplayMap.delete(oldCanvas);
      this.#renderDisplayMap.set(canvas, canvasRenderArray);
      if (canvasToLocalDisplay) {
        canvasToLocalDisplay.delete(oldCanvas);
        canvasToLocalDisplay.set(canvas, localRenderDisplay);
      }
    }

    return newRenderDisplayMap;
  }

  getRenderDisplayMap() {
    return this.#renderDisplayMap;
  }

  cleanup(canvas, display, loseContext = false) {
    if (process.env.NODE_ENV === 'development') {
      console.log('WebGLRenderDisplayMgr.cleanup');
    }
    if (display) {
      display.cleanup(null, loseContext);
    }
    // call WebglCanvas.cleanup()
    for (const [key, value] of this.#renderDisplayMap) {
      const unusedArray = value[0];
      const usedArray = value[1];
      for (const element of unusedArray) {
        element.cleanup();
      }
      for (const element of usedArray) {
        element.cleanup();
      }
    }
    this.#renderDisplayMap = new Map();
  }

  cleanupByCanvas(canvas, loseContext) {
    let renderarray = this.#renderDisplayMap.get(canvas);
    if (renderarray) {
      let canvasrenderarray = this.#renderDisplayMap.get(canvas);
      if (canvasrenderarray) {
        let unusedrenderarray = canvasrenderarray[0];
        let usedrenderarray = canvasrenderarray[1];
        usedrenderarray.forEach(function (display) {
          display.cleanup(null, loseContext);
        });
        unusedrenderarray.forEach(function (display) {
          display.cleanup(null, loseContext);
        });
        unusedrenderarray = [];
        usedrenderarray = [];
        this.#renderDisplayMap.delete(canvas);
      }
    }
  }
}

class WebGPURenderDisplayMgr extends IRenderDisplayManager {
  #videoRenderDisplayMap = new Map();
  #shareRenderDisplayMap = new Map();

  constructor() {
    super();
  }

  /**
   * Get an available render display from WebGPU render display manager.
   *
   * @param {*} canvas as the key to query a list of attached render display
   * @param {*} threadNumber the max capacity of a render display pool
   * @returns an available display or null if no available render display
   */
  getVideoRenderDisplay(canvas, canvasID, threadNumber, logErrorFn) {
    let renderDisplayPool = this.#videoRenderDisplayMap.get(canvas);
    if (!renderDisplayPool) {
      renderDisplayPool = new RenderDisplayPool(
        threadNumber,
        RenderConst.SERVE_FOR.VIDEO
      );
      renderDisplayPool.initPool(threadNumber);
      this.#videoRenderDisplayMap.set(canvas, renderDisplayPool);
    }

    let display = renderDisplayPool.pop();
    if (display) {
      display.setMultiView(true);
      return display;
    } else {
      return null;
    }
  }

  getSharingRenderDisplay(canvas, canvasID, args) {
    if (args && args.clearCache) {
      this.#shareRenderDisplayMap.clear();
    }

    let sharingRenderDisplayPool = this.#shareRenderDisplayMap.get(canvas);
    if (!sharingRenderDisplayPool) {
      sharingRenderDisplayPool = new RenderDisplayPool(
        1,
        RenderConst.SERVE_FOR.SHARE
      );
      sharingRenderDisplayPool.initPool(1);
      this.#shareRenderDisplayMap.set(canvas, sharingRenderDisplayPool);
    }

    let display = sharingRenderDisplayPool.pop();
    return display;
  }

  createVideoRenderDisplay(
    canvas,
    canvasID,
    index,
    renderer = null,
    args = null
  ) {
    const renderDisplay = new WebGPURenderDisplay(index);
    renderDisplay.addRenderer(renderer);
    renderDisplay.attachCanvas(canvas);
    return renderDisplay;
  }

  /**
   * Get the render displays which are in-use.
   * @returns a list of in-use render display, each entry has structure: { canvas: canvas, renderDisplays: [] }
   */
  getInUseCanvasRenderDisplayList(serveFor) {
    // this function will return an array of this kind of struct
    // [ { canvas: canvas1, renderDisplays: [] }, { canvas: canvas2, renderDisplays: [] }, ]
    let inUseRenderDisplays = [];
    let serveForMap = null;
    if (serveFor == RenderConst.SERVE_FOR.VIDEO) {
      serveForMap = this.#videoRenderDisplayMap;
    } else if (serveFor == RenderConst.SERVE_FOR.SHARE) {
      serveForMap = this.#shareRenderDisplayMap;
    }

    if (serveForMap) {
      for (const [key, value] of serveForMap) {
        // key : canvas
        // val : renderDisplayPool
        let inUseRdEntry = {};
        inUseRdEntry.canvas = key;
        inUseRdEntry.renderDisplays = value.getInUseRenderDisplays();

        // push to the list if have in-use render displays
        if (inUseRdEntry.renderDisplays.length > 0) {
          inUseRenderDisplays.push(inUseRdEntry);
        }
      }
    }

    return inUseRenderDisplays;
  }

  recycleRenderDisplay(renderDisplay, resMgr, clearCanvas) {
    if (renderDisplay) {
      const canvas = renderDisplay.getAttachedCanvas();
      if (canvas) {
        let videoRenderDisplayPool = this.#videoRenderDisplayMap.get(canvas);
        if (videoRenderDisplayPool) {
          renderDisplay.setWatermarkFlag(0);
          renderDisplay.setVideoMode(VIDEO_INVALID);
          videoRenderDisplayPool.recycle(renderDisplay, resMgr);
        }

        let shareRenderDisplayPool = this.#shareRenderDisplayMap.get(canvas);
        if (shareRenderDisplayPool) {
          renderDisplay.setWatermarkFlag(0);
          renderDisplay.setVideoMode(VIDEO_INVALID);
          shareRenderDisplayPool.recycle(renderDisplay, resMgr);
        }
      }
    }
  }

  cleanup(display, resMgr) {
    if (process.env.NODE_ENV === 'development') {
      console.log('WebGPURenderDisplayMgr.cleanup');
    }
    if (display) {
      display.cleanup(resMgr);
    }

    for (const [key, value] of this.#videoRenderDisplayMap) {
      value.cleanup(resMgr);
    }

    for (const [key, value] of this.#shareRenderDisplayMap) {
      value.cleanup(resMgr);
    }
  }

  cleanupByCanvas(canvas, resMgr) {
    let videoRenderDisplayPool = this.#videoRenderDisplayMap.get(canvas);
    if (videoRenderDisplayPool) {
      videoRenderDisplayPool.cleanup(resMgr);
      this.#videoRenderDisplayMap.delete(canvas);
    }

    let shareRenderDisplayPool = this.#shareRenderDisplayMap.get(canvas);
    if (shareRenderDisplayPool) {
      shareRenderDisplayPool.cleanup(resMgr);
      this.#shareRenderDisplayMap.delete(canvas);
    }
  }

  /**
   * To collect the rendering data from in-use and matched render displays.
   * @returns a list of rendering data
   */
  collectInUseRenderDisplays(serveFor) {
    let inUseRenderDisplays = this.getInUseCanvasRenderDisplayList(serveFor);
    return inUseRenderDisplays;
  }

  collectInUseRenderDisplaysByCanvas(canvas, serveFor) {
    let inUseRenderDisplays = null;
    if (canvas) {
      if (serveFor == RenderConst.SERVE_FOR.VIDEO) {
        const renderDisplayPool = this.#videoRenderDisplayMap.get(canvas);
        inUseRenderDisplays = renderDisplayPool.getInUseRenderDisplays();
      } else if (serveFor == RenderConst.SERVE_FOR.SHARE) {
        const renderDisplayPool = this.#shareRenderDisplayMap.get(canvas);
        inUseRenderDisplays = renderDisplayPool.getInUseRenderDisplays();
      }
    }

    return inUseRenderDisplays;
  }

  getRenderDisplayMap(serveFor) {
    let renderDisplayMap = null;
    if (serveFor == RenderConst.SERVE_FOR.VIDEO) {
      renderDisplayMap = this.#videoRenderDisplayMap;
    } else if (serveFor == RenderConst.SERVE_FOR.SHARE) {
      renderDisplayMap = this.#shareRenderDisplayMap;
    }
    return renderDisplayMap;
  }
}

export default RenderDisplayManager;

/**
 * It narrows a UV coordinate.
 * For example:
 * 1. uv_coord_1 = 0.6643, f(uv_coord_1, 2) = 0.66
 * 2. uv_coord_2 = 0.6691, f(uv_coord_1, 2) = 0.66
 *
 * @param {*} num a UV coordinate will be narrowed
 * @param {*} digits how many digits after decimal point will be dropped
 * @returns a narrowed UV coordinate
 */
export function narrowUvCoords(num, digits) {
  const multiplier = Math.pow(10, digits);
  return Math.floor(num * multiplier) / multiplier;
}

/**
 * It expands a UV coordinate.
 * For example:
 * 1. uv_coord_1 = 0.6643, f(uv_coord_1, 2) = 0.67
 * 2. uv_coord_2 = 0.6691, f(uv_coord_1, 2) = 0.67
 *
 * @param {*} num a UV coordinate will be expanded
 * @param {*} digits how many digits after decimal point will be expanded
 * @returns a narrowed UV coordinate
 */
export function expandUvCoords(num, digits) {
  const multiplier = Math.pow(10, digits);
  return Math.ceil(num * multiplier) / multiplier;
}

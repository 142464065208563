import * as pubsubjs from 'pubsub-js';
import isEmpty from 'lodash/isEmpty';

let PubSub = pubsubjs;
if (isEmpty(pubsubjs)) {
  PubSub = window.PubSub;
}

let Event = function () {};

Event.prototype = {
  publish() {
    return PubSub.publish.apply(this, arguments);
  },
  publishSync() {
    return PubSub.publishSync.apply(this, arguments);
  },
  trigger() {
    return this.publish.apply(this, arguments);
  },
  triggerSync() {
    return this.publishSync.apply(this, arguments);
  },
  emit() {
    return this.publish.apply(this, arguments);
  },

  // callback parameters : (message, data)
  subscribe() {
    return PubSub.subscribe.apply(this, arguments);
  },
  on() {
    return this.subscribe.apply(this, arguments);
  },
  unsubscribe() {
    return PubSub.unsubscribe.apply(this, arguments);
  },
  clearAllSubscriptions() {
    PubSub.clearAllSubscriptions();
  },
};

export default new Event();

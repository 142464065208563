/*
103,0,146,33,
2,32,0,2,
16,1,0,0,
0,0,20,12,
1,93,224,
109,94,164,46,
0,0,0,0,0,144,226 
103,0,146,34, 4

2,32,0,2, 8 ssrc 
16,1,0,0, 12
0,0,20,12, 16
1,93,225, 19
109,94,164,86,0,0,0,0,0,144,226 
*/
function s4(data) {
  return (data[0] << 24) | (data[1] << 16) | (data[2] << 8) | data[3];
}

function s2(data) {
  return (data[0] << 8) | data[1];
}
const Util = (function () {
  return {
    IsQosReport: function (data) {
      let offset = 4;
      return data[offset] == 21 && data[offset + 9] == 0;
    },
    GetQOSSeq: function (data) {
      s2(data.slice(17, 19));
    },
    GetQOSTime: function (data) {
      if (data[0] == 104) {
        return s4(data.slice(19, 23));
      }
      /// node id
      if (data[4] != 16 && data[5] != 1) return s4(data.slice(19, 23));
      else return s4(data.slice(15, 19));
    },
    IsVideoPkg: function (data) {
      return data[0] == 103;
    },
  };
})();

class ReportStatic {
  constructor(args) {
    let params = args || {};
    this.last_report_time = 0;
    this.timeout_count = 0;
    this.interval_report_time = params.interval || 3000;
    this.tag = params.tag || 'monitor';
    this.max_timeout = 0;
    this.min_timeout = 0x7fffffff;
    this.reportcallback = params.reportcallback;
    this._timeoutid = 0;
  }
  _report() {
    this.reportcallback &&
      this.reportcallback(
        this.tag,
        Math.ceil(this.max_timeout),
        Math.ceil(this.min_timeout),
        this.timeout_count
      );
    this.timeout_count = 0;
    this.max_timeout = 0;
    this.min_timeout = 0x7fffffff;
  }

  timeoutReport(elapsed, now) {
    this.timeout_count++;
    if (elapsed > this.max_timeout) this.max_timeout = elapsed;
    if (elapsed < this.min_timeout) this.min_timeout = elapsed;

    if (this._timeoutid) {
      return;
    }

    let self = this;
    this._timeoutid = setTimeout(() => {
      self._report();
      self._timeoutid = 0;
    }, this.interval_report_time);
  }
}

class BaseMonitor {
  constructor(args) {
    this._tag = args.tag || 'MONITOR';
    this._base_time = 0;
    this._interval_id = -1;
    this._timeout = Math.max(1000, args.timeout || 0);
    this._callback = args.callback;
  }

  _report() {
    let now = Date.now();
    let samples = this.getSamples(now);
    if (!samples) {
      samples = [];
    }
    let str = `${this._base_time}:${now - this._base_time}:${samples.join(
      '|'
    )}`;
    this._callback && this._callback(this._tag, str);
  }
  /**
   * user need override
   * @return {Array}
   */
  getSamples(now) {}

  onStart() {}
  onStop() {}

  start() {
    if (-1 != this._interval_id) {
      return;
    }
    this._base_time = Date.now();
    this._interval_id = setInterval(this._report.bind(this), this._timeout);
    this.onStart();
  }
  stop() {
    if (-1 != this._interval_id) {
      clearInterval(this._interval_id);
      this._interval_id = -1;
      this._report();
      this.onStop();
    }
  }
}

class RenderStatistic extends BaseMonitor {
  constructor(args) {
    super(args);
    this._count = 0;
  }
  onStart() {
    this._count = 0;
  }

  sample() {
    this._count++;
  }

  getSamples(now) {
    return [this._count];
  }
}

export { ReportStatic, Util, RenderStatistic };

import { add_monitor } from '../../worker/common/common';
import { TEX_LAYER_Z_IDX } from './RenderConst';

/**
 * RendererController controls a backend renderer how to renderer to a
 * canvas. It is stateless so all necessary components will be passed
 * directly.
 */
class RendererController {
  constructor() {}

  /**
   * To render each render display on the screen with a backend renderer.
   * It is triggered by rAF/interval callback.
   *
   * @param {*} renderer a backend renderer, may be the WebGPU renderer or WebGL/WebGL2 renderer
   * @param {*} renderDisplays an array of render display that are going to be rendered on the screen
   */
  render(renderer, renderDisplays) {
    if (!renderer) {
      console.warn('[RendererController] renderer is not attached!');
      add_monitor('[RendererController] render() renderer is not attached!');
      return;
    }

    if (!renderer.isGPUDeviceReady()) {
      console.log('[RendererController] GPU device is not ready!');
      add_monitor('[RendererController] render() GPU device is not ready!');
      return;
    }

    if (!renderDisplays || renderDisplays.length == 0) {
      console.warn('[RendererController] render displays are not available!');
      add_monitor('[RendererController] render() displays are not available!');
      return;
    }

    this.#handlePendingGPUEvents(renderDisplays);
    if (!this.#canDropRenderingRound(renderDisplays)) {
      renderer.render(renderDisplays);
    }
  }

  #handlePendingGPUEvents(renderDisplays) {
    renderDisplays.forEach((rd) => {
      rd.consumePendingGPUEvents();
    });
  }

  #canDropRenderingRound(renderDisplays) {
    if (!renderDisplays || renderDisplays.length == 0) {
      return true;
    }

    const index = renderDisplays.findIndex((rd) => {
      if (!rd) {
        return false;
      }

      const texLayer = rd.getTextureLayerByZIndex(TEX_LAYER_Z_IDX.VS_BASE);
      if (!texLayer) {
        return false;
      }

      return rd.isRenderingStateReady();
    });

    // if -1, means no such a render display is ready,
    // this round of rendering can be dropped
    return index == -1;
  }
}

export default RendererController;

import {
  GPU_RES_TYPE,
  RESOLUTION_LEVELS,
  RES_OCCUPANCY_LEVEL,
  HIG_RSL_LEVEL_OFFSET,
} from './RenderConst';
/**
 * GPUBufferPoolEntry is a recycling pool to manage GPUBuffers in an effective way.
 *
 * It uses a map to maintain the GPUBuffers with the same level and different sizes:
 * | key - bytesPerRow | val - Array of buffer |
 * | 256               | [buffer1, buffer2 ...]
 * | 512               | [buffer1, buffer2 ...]
 * Different sizes of buffers can be maintained in the same array if they have closed sizes,
 * so the buffers in the same array would be fully reused. On the other hand, trying to borrow
 * a buffer from next array is a good solution to reuse buffers.
 */
class GPUBufferPoolEntry {
  #mResType = GPU_RES_TYPE.TEXTURE_BUFFER;
  #mLevel = 0;
  #mPool = new Map();
  #mPoolThreshold = 0;

  /**
   * Constructor of GPUBufferPoolEntry.
   *
   * @param {*} level in which fixed resolution range
   * @param {*} poolThreshold how many buffers can be created and saved in this pool, if 0, no limitation
   */
  constructor(level, poolThreshold = 0) {
    this.#mLevel = level;
    this.#mPoolThreshold = poolThreshold;
  }

  /**
   * Check whether a pool has reach to the threshold.
   *
   * @param {*} level in which fixed resolution range
   * @param {*} bytesPerRow the key of pool
   * @returns if true, up to the threshold, otherwise false
   */
  isUpToThreshold(level, bytesPerRow) {
    if (level != this.#mLevel || bytesPerRow <= 0) {
      return false;
    }

    if (this.#mPoolThreshold == 0) {
      return false;
    }

    const bprPool = this.#mPool.get(bytesPerRow);
    if (bprPool) {
      return bprPool.length >= this.#mPoolThreshold;
    }

    return false;
  }

  /**
   * Push a GPUBuffer to the pool.
   *
   * @param {*} level in which fixed resolution range
   * @param {*} bytesPerRow as the key of pool which is a map
   * @param {*} gpuBuffer a GPUBuffer that will be pushed to the pool
   * @returns if true, push to the pool successfully, otherwise false
   */
  push(level, bytesPerRow, gpuBuffer) {
    if (level != this.#mLevel || !gpuBuffer || bytesPerRow <= 0) {
      return false;
    }

    let bprPool = null;
    let needToSort = false;
    if (this.#mPool.has(bytesPerRow)) {
      bprPool = this.#mPool.get(bytesPerRow);
      if (!bprPool) {
        bprPool = [];
        needToSort = true;
      }

      if (bprPool.length < this.#mPoolThreshold) {
        bprPool.push(gpuBuffer);
      } else {
        // console.log(`[GPUBufferPoolEntry] push() is up to threshold!`);
        return false;
      }
    } else {
      bprPool = [];
      bprPool.push(gpuBuffer);
      needToSort = true;
    }

    if (needToSort) {
      this.#setToPool(bytesPerRow, bprPool, needToSort);
    }

    return true;
  }

  /**
   * Acquire a GPUBuffer from pool.
   *
   * @param {*} bufferConfig the buffer configuration
   * @param {*} isSameKey if true, acquire a buffer from the pool with same bytesPerRow value
   * @returns an available GPUBuffer with mapState mapped or null
   */
  acquire(bufferConfig, isSameKey = false) {
    if (!bufferConfig) {
      return null;
    }

    let level = bufferConfig.level;
    let bytesPerRow = bufferConfig.bytesPerRow;
    let requiredBufferSize = bufferConfig.size;

    if (level != this.#mLevel || bytesPerRow <= 0) {
      console.error(
        `[GPUBufferPoolEntry] acquire() level(${level}) or bpr=${bytesPerRow} is invalid!`
      );
      return null;
    }

    let gpuBuffer = null;
    let tryToBorrow = false;
    if (this.#mPool.has(bytesPerRow)) {
      let bprPool = this.#mPool.get(bytesPerRow);
      if (bprPool) {
        const matchedIndex = bprPool.findIndex(
          (buffer) =>
            buffer.mapState == 'mapped' && buffer.size >= requiredBufferSize
        );

        if (matchedIndex > -1) {
          gpuBuffer = bprPool.splice(matchedIndex, 1)[0];
          // console.log(`[BufferPoolEntry] has available buffer with level(${level}) and bpr(${bytesPerRow})`);
        } else {
          tryToBorrow = true;
        }
      } else {
        tryToBorrow = true;
      }
    } else {
      tryToBorrow = true;
    }

    // here define an interesting action: borrow from next level.
    // sometimes, like sharing from desktop client, it has the standalone cursor data that needs to be rendered
    // on the display separately. Different shapes of a cursor may have different sizes but the sizes are closed,
    // like 32x25 and 70x63. Most of time the size of the cursor is 70x63 so many GPUBuffers would be created for
    // this size. When the cursor moves to a text box, it will become a text select cursor with a small size,
    // like a vertical line. We don't need to create a new GPUBuffer for the new shape of cursor, just borrow one
    // from the pool which holds the 70x63 size of GPUBuffers.
    if (tryToBorrow && !gpuBuffer && !isSameKey) {
      let tryCount = 2;
      let tryAll = false;
      if (level >= RESOLUTION_LEVELS[HIG_RSL_LEVEL_OFFSET]) {
        tryAll = true;
      }

      for (const [key, val] of this.#mPool) {
        if (tryCount > 0 || tryAll) {
          if (key > bytesPerRow) {
            gpuBuffer = this.#borrowFromBprPool(val, requiredBufferSize);
            if (gpuBuffer) {
              bufferConfig.bytesPerRow = key;
              // console.log(
              //   `[GPUBufferPoolEntry] acquire() borrow buffer from pool(level:${level}, bpr:${key})`
              // );
              break;
            } else {
              tryCount--;
            }
          }
        }
      }
    }

    return gpuBuffer;
  }

  /**
   * Recycle a GPUBuffer to a pool.
   *
   * @param {*} level in which fixed resolution range
   * @param {*} bytesPerRow as key of the pool
   * @param {*} gpuBuffer a buffer will be recycled
   * @returns if true, the buffer is recycled, otherwise false
   */
  recycle(level, bytesPerRow, gpuBuffer) {
    if (level != this.#mLevel || bytesPerRow <= 0 || !gpuBuffer) {
      return false;
    }

    let isRecycled = false;
    if (this.#mPool.has(bytesPerRow)) {
      let needToSort = false;
      let bprPool = this.#mPool.get(bytesPerRow);
      if (!bprPool) {
        bprPool = [];
        needToSort = true;
      }

      bprPool.push(gpuBuffer);
      if (needToSort) {
        this.#setToPool(bytesPerRow, bprPool, needToSort);
      }
      isRecycled = true;
    } else {
      isRecycled = this.push(level, bytesPerRow, gpuBuffer);
    }

    return isRecycled;
  }

  /**
   * Release GPUBuffer resource by an occupancy level.
   *
   * @param {*} level occupancy level defines how to release resource
   */
  release(level) {
    if (level == RES_OCCUPANCY_LEVEL.OVERUSE) {
      for (const [key, val] of this.#mPool) {
        if (val) {
          for (const buffer of val) {
            if (buffer.mapState == 'mapped' || buffer.mapState == 'unmapped') {
              buffer.destroy();
            }
          }
          val.length = 0;
        }
      }

      this.#mPool.clear();
    }
  }

  /**
   * Cleanup all GPUBuffers.
   */
  cleanup() {
    for (const [key, val] of this.#mPool) {
      if (val) {
        for (const buffer of val) {
          if (buffer.mapState == 'mapped' || buffer.mapState == 'unmapped') {
            buffer.destroy();
          }
        }
        val.length = 0;
      }
    }

    this.#mPool.clear();
  }

  getPool() {
    return this.#mPool;
  }

  hasBytesPerRowAsKey(bytesPerRow) {
    return this.#mPool.has(bytesPerRow);
  }

  getResourceType() {
    return this.#mResType;
  }

  getPoolThreshold() {
    return this.#mPoolThreshold;
  }

  /**
   * Check whether can borrow a GPUBuffer from next level with the same width.
   *
   * @param {*} level height level of a GPUBuffer
   * @param {*} bytesPerRow width as bytesPerRow
   * @returns a GPUBuffer or null
   */
  canLendBufferCrossLevel(level, bytesPerRow) {
    let canLend = true;
    if (this.#mPool.has(bytesPerRow)) {
      const bprPool = this.#mPool.get(bytesPerRow);
      if (bprPool) {
        let availableCount = 0;
        let pendingCount = 0;
        for (const buffer of bprPool) {
          if (buffer.mapState == 'mapped') {
            availableCount += 1;
          } else {
            pendingCount += 1;
          }
        }

        if (level >= RESOLUTION_LEVELS[HIG_RSL_LEVEL_OFFSET]) {
          canLend = availableCount > 0;
        } else {
          const isAllBufferAvailable = availableCount > 0 && pendingCount == 0;
          const hasBackupAvailableBuffer =
            availableCount >= 2 && pendingCount != 0;
          canLend = isAllBufferAvailable || hasBackupAvailableBuffer;
        }
      }
    } else {
      canLend = false;
    }

    return canLend;
  }

  #setToPool(key, val, needToSort) {
    this.#mPool.set(key, val);

    if (needToSort) {
      let entries = Array.from(this.#mPool.entries());
      entries.sort((a, b) => a[0] - b[0]);
      this.#mPool.clear();
      entries.forEach(([key, value]) => {
        this.#mPool.set(key, value);
      });
    }
  }

  #borrowFromBprPool(bprPool, requiredBufferSize) {
    if (!bprPool || bprPool.length == 0) {
      return null;
    }

    let availableCount = 0;
    let pendingCount = 0;
    let candidate = null;
    for (const buffer of bprPool) {
      if (buffer.mapState == 'mapped') {
        availableCount += 1;
        if (!candidate) {
          if (buffer.size >= requiredBufferSize) {
            candidate = buffer;
          }
        }
      } else {
        pendingCount += 1;
      }
    }

    const isAllBufferAvailable = availableCount > 0 && pendingCount == 0;
    const hasBackupAvailableBuffer = availableCount >= 2 && pendingCount != 0;
    // console.log(
    //   `[BufferPoolEntry] borrowFromBprPool() isAllAvailable=${isAllBufferAvailable} hasBackup=${hasBackupAvailableBuffer}`
    // );
    if (isAllBufferAvailable || hasBackupAvailableBuffer) {
      if (candidate) {
        // if candidate buffer is ready, remove it from the pool in next level
        const index = bprPool.indexOf(candidate);
        if (index != -1) {
          bprPool.splice(index, 1);
        }
      }

      return candidate;
    } else {
      return null;
    }
  }
}

export default GPUBufferPoolEntry;

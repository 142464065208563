import Queue from './queue';
import globalTracingLogger from './globalTracingLogger';

/**
 * zoom each UDP packet won't be more than 1500 bytes
 */
const BYTES_PER_ELEMENT = 1500;
const defaultcapacity = 80;
const MAX_REPUSH_COUNT_LOG = 10;
const MAX_CACHE_DATA_SIZE = 1000;
const DefaultRePushTimeout = 30;
export class RingBuffer {
  static getStorageForCapacity(
    capacity = defaultcapacity,
    bytesPerElement = BYTES_PER_ELEMENT
  ) {
    var bytes = 8 + (capacity + 1) * bytesPerElement;
    return new SharedArrayBuffer(bytes);
  }
  // `sab` is a SharedArrayBuffer with a capacity calculated by calling
  // `getStorageForCapacity` with the desired capacity.
  // usingOneElementBuffer == true : if consume data is copied, do not malloc new memory(ArrayBuffer) for the element, reuse the same ArrayBuffer, reduce GC.
  constructor(
    sab,
    bytesPerElement = BYTES_PER_ELEMENT,
    label = '',
    usingOneElementBuffer = false,
    offset = 0,
    length = sab.byteLength,
    wasmMemory
  ) {
    this.offset = offset;
    this._BYTES_PER_ELEMENT = bytesPerElement;
    this.capacity = (length - 8) / bytesPerElement;
    // The usable capacity for the ring buffer: the number of elements that can be stored
    this.usableCapacity = this.capacity - 1;
    this.buf = sab;
    this.write_ptr = new Uint32Array(this.buf, offset, 1);
    this.read_ptr = new Uint32Array(this.buf, offset + 4, 1);
    this.storageUint8sByteOffset = offset + 8;
    this.storageUint8s = new Uint8Array(
      this.buf,
      this.storageUint8sByteOffset,
      length - 8
    );
    this.byteLength = length;
    this.label = label;
    this.usingOneElementBuffer = usingOneElementBuffer;
    if (wasmMemory) {
      this.wasmMemory = wasmMemory;
    }
    if (usingOneElementBuffer) {
      this.oneElementBuffer = new ArrayBuffer(bytesPerElement);
    }
    this.repushhander = 0;
    this.repushlogcount = 0;
    this.monitorpace = 0;
  }

  checkBuffer() {
    if (this.wasmMemory && this.wasmMemory.buffer != this.buf) {
      console.log('buffer change');
      this.buf = this.wasmMemory.buffer;

      this.storageUint8s = new Uint8Array(
        this.buf,
        this.storageUint8sByteOffset,
        this.byteLength - 8
      );
    }
  }

  enqueue(uint8s) {
    if (this.available_write() > 0) {
      this.push(uint8s);
    }
    var rd = Atomics.load(this.read_ptr, 0);
    var wr = Atomics.load(this.write_ptr, 0);
    return { rd, wr };
  }

  enqueueSafe(uint8s, use_cache = true, monitorhandle = undefined) {
    if (!this.dataBuffer) {
      this.dataBuffer = new Queue();
    }

    while (this.dataBuffer.getLength() > 0 && this.available_write() > 0) {
      let bufferdata = this.dataBuffer.dequeue();
      if (bufferdata) {
        this.push(bufferdata);
      }
    }
    let que_size = this.dataBuffer.getLength();

    if (uint8s) {
      if (this.available_write() > 0 && que_size == 0) {
        this.push(uint8s);
        return true;
      }
      if (!use_cache) {
        return false;
      }
      this.dataBuffer.enqueue(uint8s);
      ++que_size;
    }

    if (que_size > 0 && !this.repushhander) {
      this.repushhander = setTimeout(() => {
        if (this.repushlogcount % MAX_REPUSH_COUNT_LOG == 0) {
          console.warn('<<< retry consume cache data');
        }
        this.repushlogcount++;
        this.repushhander = 0;
        this.enqueueSafe(null);
      }, DefaultRePushTimeout);
    }

    if (que_size >= MAX_CACHE_DATA_SIZE) {
      globalTracingLogger.warn(
        'Cached data in SAB reached critical value, will be cleared'
      );
      this.dataBuffer.clear();
      monitorhandle && monitorhandle('vqslclear');
    }

    if (que_size > 0 && monitorhandle) {
      let time = performance.now();
      if (!this.monitorpace || time - this.monitorpace > 20 * 1000) {
        this.monitorpace = time;
        monitorhandle && monitorhandle('vqsl' + que_size);
      }
    }
    return true;
  }

  /**
   *
   * @param uint8s
   * @returns {{rd: number, wr: number}|number}
   */
  push(uint8s) {
    if (uint8s instanceof Array) {
      return this._pushArray(uint8s);
    } else {
      return this._push(uint8s);
    }
  }

  _pushArray(uint8sList) {
    var wr = Atomics.load(this.write_ptr, 0);
    this.checkBuffer();

    let alreadyWriteBytesLen = 0;
    uint8sList.forEach((uint8s) => {
      this.storageUint8s.set(
        uint8s,
        wr * this._BYTES_PER_ELEMENT + 8 + 4 + alreadyWriteBytesLen
      ); // 4 is storing current frame byteLength
      alreadyWriteBytesLen += uint8s.byteLength;
    });

    let uint8sLenWriter = new Uint32Array(
      this.buf,
      this.offset + wr * this._BYTES_PER_ELEMENT + 8,
      1
    );
    uint8sLenWriter[0] = alreadyWriteBytesLen;

    // publish the enqueued data to the other side
    let nextWR = (wr + 1) % this.capacity;
    Atomics.store(this.write_ptr, 0, nextWR);
    return true;
  }

  _push(uint8s) {
    var wr = Atomics.load(this.write_ptr, 0);
    this.checkBuffer();

    this.storageUint8s.set(
      uint8s,
      wr * this._BYTES_PER_ELEMENT + 8 + 4,
      uint8s.byteLength
    );
    let uint8sLenWriter = new Uint32Array(
      this.buf,
      this.offset + wr * this._BYTES_PER_ELEMENT + 8,
      1
    );
    uint8sLenWriter[0] = uint8s.byteLength;

    // publish the enqueued data to the other side
    let nextWR = (wr + 1) % this.capacity;
    Atomics.store(this.write_ptr, 0, nextWR);
    return true;
  }

  addReadPtr() {
    var rd = Atomics.load(this.read_ptr, 0);
    Atomics.store(this.read_ptr, 0, (rd + 1) % this.capacity);
  }

  /**
     * if bCopyData is false,require execute {@link addReadPtr()} after using this dequeued data/uint8s
     * if bCopyData is true, do not manually execute {@link addReadPtr()}
     * @param bCopyData
     * @returns {Uint8Array| {bCopyData:boolean,
            uint8s:Uint8Array,
            begin: Number,
            end: Number}}
     */
  dequeue(bCopyData = true) {
    var rd = Atomics.load(this.read_ptr, 0);
    this.checkBuffer();

    let uint8sLenReader = new Uint32Array(
      this.buf,
      this.offset + rd * this._BYTES_PER_ELEMENT + 8,
      1
    );
    let uint8s;
    let subArrayOffsetBegin;
    let subArrayOffsetEnd;
    if (bCopyData) {
      if (this.oneElementBuffer) {
        // do not create new buffer, for less GC
        uint8s = new Uint8Array(this.oneElementBuffer, 0, uint8sLenReader[0]);
      } else {
        uint8s = new Uint8Array(uint8sLenReader[0]);
      }
      let newTypeView = new Uint8Array(
        this.storageUint8s.buffer,
        rd * this._BYTES_PER_ELEMENT + 8 + 4 + this.storageUint8sByteOffset,
        uint8s.byteLength
      );
      uint8s.set(newTypeView, 0);
    } else {
      uint8s = this.storageUint8s.subarray(
        rd * this._BYTES_PER_ELEMENT + 8 + 4,
        rd * this._BYTES_PER_ELEMENT + 8 + 4 + uint8sLenReader[0]
      );
      subArrayOffsetBegin =
        rd * this._BYTES_PER_ELEMENT + 8 + 4 + this.storageUint8sByteOffset;
      subArrayOffsetEnd =
        rd * this._BYTES_PER_ELEMENT +
        8 +
        4 +
        uint8sLenReader[0] +
        this.storageUint8sByteOffset;
    }

    // if shared buffer to outside, read ptr cannot be
    if (bCopyData) {
      Atomics.store(this.read_ptr, 0, (rd + 1) % this.capacity);
    }

    return bCopyData
      ? uint8s
      : {
          bCopyData,
          uint8s,
          begin: subArrayOffsetBegin,
          end: subArrayOffsetEnd,
        };
  }

  // Number of elements available for reading. This can be late, and report less
  // elements that is actually in the queue, when something has just been
  // enqueued.
  available_read() {
    var rd = Atomics.load(this.read_ptr, 0);
    var wr = Atomics.load(this.write_ptr, 0);
    return this._available_read(rd, wr);
  }

  // Number of elements available for writing. This can be late, and report less
  // elements that is actually available for writing, when something has just
  // been dequeued.
  available_write() {
    var rd = Atomics.load(this.read_ptr, 0);
    var wr = Atomics.load(this.write_ptr, 0);
    return this._available_write(rd, wr);
  }

  is_available_write() {
    var rd = Atomics.load(this.read_ptr, 0);
    var wr = Atomics.load(this.write_ptr, 0);
    return this._is_available_write(rd, wr);
  }

  // private methods //

  // Number of elements available for reading, given a read and write pointer..
  _available_read(rd, wr) {
    return (wr + this.capacity - rd) % this.capacity;
  }

  // Number of elements available from writing, given a read and write pointer.
  _available_write(rd, wr) {
    return this.usableCapacity - this._available_read(rd, wr);
  }
  // return whether available writing or not
  _is_available_write(rd, wr) {
    return this._available_write(rd, wr) > 0;
  }

  _storage_capacity() {
    return this.capacity;
  }
}

export class ConsumeRB {
  /**
   * @param rb {RingBuffer}
   */
  constructor(
    rb,
    dataCallback,
    timeoutCallback = null,
    timeoutMS = 50,
    maxCount = defaultcapacity
  ) {
    if (!(rb instanceof RingBuffer)) throw new Error('RingBuffer required');
    this.rb = rb;
    this.dataCallback = dataCallback;
    this.interval = null;
    this.requestID = null;
    this.timeout_call = timeoutCallback;
    this.tick_lasted_time = 0;
    this.timeoutMS = timeoutMS;
    this.maxCount = maxCount;
  }

  consume(intervalMS = 20, bCopyData = true) {
    if (this.interval) return;
    this.bCopyData = bCopyData;
    this.interval = setInterval(() => {
      let now = performance.now();
      if (this.timeout_call) {
        if (this.tick_lasted_time != 0) {
          let elapsed = now - this.tick_lasted_time;
          if (elapsed >= this.timeoutMS) {
            this.timeout_call(elapsed, now);
          }
        }
        this.tick_lasted_time = now;
      }
      this._dequeue();
    }, intervalMS);
    console.log(`consume interval ${this.interval}`);
  }

  /**
   * no interval inside
   * For some reason, setInterval is not working as normal if Chrome tab is minimized,
   * then {@link consume} is not working well,  {@link consumeAll} is needed
   */
  consumeAll(bCopyData = true) {
    this.bCopyData = bCopyData;
    this._dequeue();
  }

  _dequeue() {
    let limit = Math.min(this.rb.available_read(), this.maxCount);
    this.consoume_count = 0;
    while (this.consoume_count < limit) {
      this.consoume_count++;
      let data = this.rb.dequeue(this.bCopyData);
      this.dataCallback(data);
      if (!this.bCopyData) {
        this.rb.addReadPtr();
      }
    }
  }

  _consumeForAnimationFrame() {
    this._dequeue();
    this.requestID = requestAnimationFrame(
      this._consumeForAnimationFrame.bind(this)
    );
  }

  consumeUsingRequestAnimationFrame(bCopyData = true) {
    if (this.requestID) return;
    this.bCopyData = bCopyData;
    this._consumeForAnimationFrame();
  }

  cancelConsume() {
    console.log(
      `cancelConsume interval ${this.interval} requestID ${this.requestID}`
    );
    this.tick_lasted_time = 0;
    clearInterval(this.interval);
    if (this.requestID) {
      cancelAnimationFrame(this.requestID);
    }
    this.interval = null;
    this.requestID = null;
  }
}

export class VideoDecodeOBJXBuffer {
  constructor() {
    this.timeStampKey = 'video_timestamp';
    this.keysList = [
      'video_ssrc',
      'video_width',
      'video_height',
      'rendering_x',
      'rendering_y',
      'rendering_w',
      'rendering_h',
      'rotation',
      'yuv_limited',
    ];
    this.bCopyData = null;
    this.begin = null;
    this.end = null;
  }

  /**
   * @param obj.video_ssrc {Number} - ssrc 4bytes
   * @param obj.video_width {Number} - width 4bytes
   * @param obj.video_height {Number} - height 4bytes
   * @param obj.rendering_x {Number}  - r_x 4bytes
   * @param obj.rendering_y {Number} - r_y 4bytes
   * @param obj.rendering_w {Number} - r_w 4bytes
   * @param obj.rendering_h {Number} - r_h 4bytes
   * @param obj.rotation {Number} - rotation 4bytes
   * @param obj.yuv_limited {Number} - yuv_limited 4bytes
   * @param obj.video_timestamp {Number} - ntptime 8bytes
   * @param obj.data {Uint8Array} - yuvdata
   */
  setOBJ(obj) {
    this.obj = obj;
    this.yuvUint8s = obj.data;
  }

  setBuffer(uint8s) {
    if (uint8s.bCopyData === false) {
      this.objUint8s = uint8s.uint8s;
      this.bCopyData = uint8s.bCopyData;
      this.begin = uint8s.begin;
      this.end = uint8s.end;
    } else {
      this.objUint8s = uint8s;
      this.bCopyData = true;
      this.begin = 0;
      this.end = uint8s.byteLength;
    }
  }

  buffer2Obj() {
    let uint32s = new Uint32Array(this.objUint8s.buffer, this.begin, 9);
    let uint64sDV = new DataView(
      this.objUint8s.buffer,
      this.begin + 4 * 10,
      16
    );

    let obj = {};
    this.keysList.forEach((item, index) => {
      obj[item] = uint32s[index];
    });

    obj[this.timeStampKey] = Number(uint64sDV.getBigUint64(0, true));
    let byteLen = Number(uint64sDV.getBigUint64(8, true));
    let uint8s;
    let uint8sSource = new Uint8Array(
      this.objUint8s.buffer,
      this.begin + 4 * 10 + 8 + 8,
      byteLen
    );
    if (this.bCopyData) {
      uint8s = uint8sSource;
    } else {
      uint8s = uint8sSource;
    }

    obj.data = uint8s;
    return obj;
  }

  /**
   * @returns {Array<Uint8Array>}
   */
  obj2buffer() {
    /**
     *  4 * 10 => 9 field + 4 empty bytes, each is 4 bytes,  then 8 bytes is timeStamp value, then 8 bytes is yuvUint8s.byteLength
     *
     *  why need 4 empty bytes?
     *  start offset of BigUint64Array should be a multiple of 8
     * @type {Uint8Array}
     */
    let uint8s = new Uint8Array(4 * 10 + 8 + 8);
    let keysList = this.keysList;

    let uint32s = new Uint32Array(uint8s.buffer, 0, 9);
    let uint64sDV = new DataView(uint8s.buffer, 4 * 10, 16);

    keysList.forEach((item, index) => {
      uint32s[index] = this.obj[item];
    });

    uint64sDV.setBigUint64(0, BigInt(this.obj[this.timeStampKey]), true);
    uint64sDV.setBigUint64(8, BigInt(this.yuvUint8s.byteLength), true);

    return [uint8s, this.yuvUint8s];
  }
}

/**
 * A helper class that helps to query GPUAdapter features.
 */
class GPUFeaturesHelper {
  #mAdapter = null;
  #mAdapterFeatures = null;

  constructor(adapter) {
    this.#mAdapter = adapter;
    if (adapter) {
      this.#mAdapterFeatures = adapter.features;
    }
  }

  /**
   * Get all the features that GPUAdapter supports.
   *
   * @returns a map of supported features
   */
  getAdapterFeatures() {
    return this.#mAdapterFeatures;
  }

  getAdapterLimits() {
    if (!this.#mAdapter) {
      return null;
    }

    return this.#mAdapter.limits;
  }

  queryMaxTextureDimension2D() {
    const limits = this.getAdapterLimits();
    if (limits) {
      return limits.maxTextureDimension2D;
    }

    return 0;
  }

  queryMaxBufferSize() {
    const limits = this.getAdapterLimits();
    if (limits) {
      return limits.maxBufferSize;
    }

    return 0;
  }

  /**
   * Helps to query whether a feature is supported.
   *
   * @param {*} featureLabel label or name of the feature
   * @returns if true, feature is supported, if false, not supported
   */
  queryAdapterFeature(featureLabel) {
    if (!this.#mAdapterFeatures || !featureLabel) {
      return false;
    }

    return this.#mAdapterFeatures.has(featureLabel);
  }

  /**
   * Query whether or not the timestamp-query feature is supported by GPUAdapter.
   * @returns if true, supported, if false, not supported
   */
  isTimestampQuerySupported() {
    const label = 'timestamp-query';
    return this.queryAdapterFeature(label);
  }

  getGPUAdapter() {
    return this.#mAdapter;
  }

  /**
   * Cleanup all the attached resources.
   */
  cleanup() {
    this.#mAdapter = null;
    this.#mAdapterFeatures = null;
  }
}

export default GPUFeaturesHelper;

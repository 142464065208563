import GPUBufferManager from './GPUBufferManager';
import GPUTextureManager from './GPUTextureManager';
import GPUBufferPool from './GPUBufferPool';
import GPUFeaturesHelper from './GPUFeaturesHelper';
import GPUResourcesWatchDog from './GPUResourcesWatchDog';
import globalTracingLogger from '../globalTracingLogger';

/**
 * WebGPUResManager will provide some key components while the renderer
 * is WebGPU renderer.
 *
 * For example, WebGPUResManager will not provide a new GPUDevice while a
 * created GPUDevice is working, the cached GPUDevice will be returned when
 * you want one.
 */
class WebGPUResManager {
  #mGPUAdapter = null;
  #mGPUAdapterInfo = null;
  #mGPUDevice = null;
  #mCanvasFormat = null;
  #mGPUBufferMgr = null;
  #mGPUTextureMgr = null;
  #mGPUBufferPool = null;
  #mGPUFeaturesHelper = null;
  #mGPUResWatchDog = null;
  #mRendererProvider = null;
  #mLabel = '';

  constructor() {}

  addRendererProviderModule(rendererProvider) {
    this.#mRendererProvider = rendererProvider;
  }

  setLabel(label) {
    this.#mLabel = label;
  }

  async initialize() {
    if (!navigator.gpu) {
      console.error(`[WebGPUResManager] initialize() WebGPU is not supported!`);
      return;
    }

    if (!this.#mGPUAdapter) {
      this.#mGPUAdapter = await navigator.gpu.requestAdapter();
      if (!this.#mGPUAdapter) {
        console.error(
          "[WebGPUResManager] initialize() Couldn't request WebGPU adapter."
        );
        return;
      }
    }

    if (!this.#mGPUDevice) {
      this.#mGPUDevice = await this.#mGPUAdapter.requestDevice();
      this.#mGPUDevice.lost.then(async (info) => {
        if (info.reason != 'destroyed') {
          console.error(
            `WebGPU device was lost: ${info.message} reason=${info.reason}`
          );
          globalTracingLogger.error(
            `WebGPU device was lost: ${info.message} reason=${info.reason}`
          );
        }

        if (this.#mRendererProvider) {
          this.#mRendererProvider.rendererUnconfigureGPUContext();
        }

        // Many causes for lost devices are transient, so applications should try getting a
        // new device once a previous one has been lost unless the loss was caused by the
        // application intentionally destroying the device. Note that any WebGPU resources
        // created with the previous device (buffers, textures, etc) will need to be
        // re-created with the new one.
        if (info.reason != 'destroyed') {
          this.#mGPUDevice = null;
          await this.initialize();
          if (this.#mRendererProvider) {
            this.#mRendererProvider.rendererReinitialize();
          }
        }
      });
    }

    if (!this.#mGPUAdapterInfo) {
      this.#mGPUAdapterInfo = await this.#mGPUAdapter.requestAdapterInfo();
    }

    if (!this.#mCanvasFormat) {
      this.#mCanvasFormat = navigator.gpu.getPreferredCanvasFormat();
    }

    if (!this.#mGPUBufferMgr) {
      this.#mGPUBufferMgr = new GPUBufferManager(this.#mGPUDevice);
    }

    if (!this.#mGPUTextureMgr) {
      this.#mGPUTextureMgr = new GPUTextureManager(this.#mGPUDevice);
    }

    if (!this.#mGPUBufferPool) {
      this.#mGPUBufferPool = new GPUBufferPool(this.#mGPUDevice);
    }

    if (!this.#mGPUFeaturesHelper) {
      this.#mGPUFeaturesHelper = new GPUFeaturesHelper(this.#mGPUAdapter);
    }

    if (!this.#mGPUResWatchDog) {
      this.#mGPUResWatchDog = new GPUResourcesWatchDog(this.#mLabel);
      this.#mGPUResWatchDog.addObservable(this.#mGPUBufferMgr);
      this.#mGPUResWatchDog.addObservable(this.#mGPUTextureMgr);
      this.#mGPUResWatchDog.addObservable(this.#mGPUBufferPool);
      this.#mGPUResWatchDog.monitor();
    }
  }

  acquireGPUDevice() {
    return this.#mGPUDevice;
  }

  acquireCanvasFormat() {
    return this.#mCanvasFormat;
  }

  acquireGPUAdapterInfo() {
    return this.#mGPUAdapterInfo;
  }

  destroyGPUDevice() {
    if (this.#mGPUDevice) {
      this.#mGPUDevice.destroy();
      this.#mGPUDevice = null;
    }
  }

  acquireGPUBufferMgr() {
    return this.#mGPUBufferMgr;
  }

  acquireGPUTextureMgr() {
    return this.#mGPUTextureMgr;
  }

  acquireGPUBufferPool() {
    return this.#mGPUBufferPool;
  }

  acquireGPUFeaturesHelper() {
    return this.#mGPUFeaturesHelper;
  }

  /**
   * Notify modules to destroy and cleanup resources.
   * Note: here is the real place where you need to destroy GPU resources.
   */
  cleanup() {
    if (this.#mGPUBufferMgr) {
      this.#mGPUBufferMgr.cleanup();
      this.#mGPUBufferMgr = null;
    }

    if (this.#mGPUTextureMgr) {
      this.#mGPUTextureMgr.cleanup();
      this.#mGPUTextureMgr = null;
    }

    if (this.#mGPUBufferPool) {
      this.#mGPUBufferPool.cleanup();
      this.#mGPUBufferPool = null;
    }

    if (this.#mGPUFeaturesHelper) {
      this.#mGPUFeaturesHelper.cleanup();
      this.#mGPUFeaturesHelper = null;
    }

    if (this.#mGPUResWatchDog) {
      this.#mGPUResWatchDog.cleanup();
      this.#mGPUResWatchDog = null;
    }

    this.#mRendererProvider = null;
    this.destroyGPUDevice();
  }
}

export default WebGPUResManager;

import { add_monitor } from '../../worker/common/common';
import { globaltracing_error } from '../../worker/common/common';
import * as RenderConst from './RenderConst';

/**
 * The backend render of WebGPU.
 * It defines the actions that how to render content to canvas via
 * a WebGPU renderer.
 *
 * Note, unlike WebGL which supports MSAA default, WebGPU must implement
 * it by our own. MSAA is supported but not default enabled here.
 */
class WebGPURenderer {
  #mCanvas = null;
  #mTextureIndex = 0;
  #mTextureStride = 0;
  #mIsInitMask = false;
  #mEnableMsaa = false;

  /* WebGPU components */
  #mResMgr = null;
  #mWGPUContext = null;
  #mDevice = null;
  #mFormat = null;
  #mGlobalCommandEncoder = null;
  #mBufferMgr = null;
  #mBufferPool = null;
  #mTextureMgr = null;
  #mVertexBuffer = null;
  #mVideoFrameRenderPipeline = null;
  #mVideoFrameSampler = null;
  #mI420YuvTextureRenderPipeline = null;
  #mNV12YuvTextureRenderPipeline = null;
  #mYuvTexturesSamplers = null;
  #mWatermarkTextureRenderPipeline = null;
  #mCursorTextureRenderPipeline = null;
  #mBlendTextureSampler = null;
  #mMultisamplingTexture = null;

  /* WebGPU data structures */
  #vtxCoordArray = new Float32Array(12);

  constructor(canvas, resMgr) {
    if (!resMgr) {
      throw new Error(`[WebGPURenderer] resMgr is an invalid param! ${resMgr}`);
    }

    this.#mCanvas = canvas;
    this.#mResMgr = resMgr;
    this.initialize(canvas);
  }

  switchMsaa(msaaEnabled) {
    this.#mEnableMsaa = msaaEnabled;
  }

  isMsaaEnabled() {
    return this.#mEnableMsaa;
  }

  setCanvas(canvas) {
    if (canvas) {
      this.#mCanvas = canvas;
    }
  }

  setDevice(device) {
    if (device) {
      this.#mDevice = device;
    }
  }

  setRenderArgs(index, isInitMask) {
    this.#mTextureIndex = index ? index : 0;
    this.#mTextureStride = !this.#mTextureIndex ? (isInitMask ? 4 : 6) : 3;
    this.#mIsInitMask = isInitMask ? isInitMask : false;
  }

  setTextureIndex(index) {
    this.#mTextureIndex = index ? index : 0;
  }

  initialize(canvas) {
    if (!this.#mDevice) {
      this.#mDevice = this.#mResMgr.acquireGPUDevice();
      if (!this.#mDevice) {
        globaltracing_error(
          `[WebGPURenderer] initialize() device is not ready!`
        );
        return;
      }
    }

    if (!this.#mFormat) {
      this.#mFormat = this.#mResMgr.acquireCanvasFormat();
    }

    if (!this.#mBufferMgr) {
      this.#mBufferMgr = this.#mResMgr.acquireGPUBufferMgr();
    }

    if (!this.#mBufferPool) {
      this.#mBufferPool = this.#mResMgr.acquireGPUBufferPool();
    }

    if (!this.#mTextureMgr) {
      this.#mTextureMgr = this.#mResMgr.acquireGPUTextureMgr();
    }

    this.configureGPUContext(canvas);
    this.#updateVtxCoords(RenderConst.BASIC_VTX_COORD_ARRAY);
  }

  isGPUDeviceReady() {
    return this.#mDevice != null;
  }

  render(renderDisplays) {
    if (this.#mEnableMsaa) {
      this.#renderWithMsaa(renderDisplays);
    } else {
      this.#renderNoMsaa(renderDisplays);
    }
  }

  #renderNoMsaa(renderDisplays) {
    // here we need to call `markRenderingStatePending()` first of all
    // to ensure the rendering state is reset to pending.
    // if any exception here, the next round of rendering can be executed
    // normally after handling the exception and resetting the state to pending
    if (renderDisplays) {
      renderDisplays.forEach((renderDisplay) => {
        renderDisplay.markRenderingStatePending();
      });
    }

    const texLayersMap = this.#evalTextureLayers(renderDisplays);
    if (!this.#mDevice || !this.#mWGPUContext || !this.#mBufferMgr) {
      this.#recycleInUsedGPUBuffers(texLayersMap);
      return;
    }

    if (
      !this.#mCanvas ||
      this.#mCanvas.width == 0 ||
      this.#mCanvas.height == 0 ||
      !this.#mWGPUContext ||
      !this.#mWGPUContext.getCurrentTexture() ||
      this.#mWGPUContext.getCurrentTexture().width == 0 ||
      this.#mWGPUContext.getCurrentTexture().height == 0
    ) {
      this.#recycleInUsedGPUBuffers(texLayersMap);
      return;
    }

    // this.#mDevice.pushErrorScope('validation');
    try {
      // 1. create a buffer for vertex coord
      if (!this.#mVertexBuffer) {
        const size = this.#vtxCoordArray.byteLength;
        const keyTag = size;
        this.#mVertexBuffer = this.#mBufferMgr.acquireBuffer(
          keyTag,
          GPUBufferUsage.VERTEX | GPUBufferUsage.STORAGE,
          size,
          true,
          false
        );
        new Float32Array(this.#mVertexBuffer.getMappedRange()).set(
          this.#vtxCoordArray
        );
        this.#mVertexBuffer.unmap();
      }

      // 1 - prepare rendering resource
      const commandEncoder = this.#acquireGlobalCommandEncoder();
      for (const [zIndex, texLayers] of texLayersMap) {
        this.#prepareRenderingResource(zIndex, texLayers, commandEncoder);
      }

      // 2 - record rendering commands
      const desc = this.#evalRenderPassDescriptorNoMsaa(
        this.#mWGPUContext.getCurrentTexture().createView()
      );

      const renderPass = commandEncoder.beginRenderPass(desc);
      renderPass.setVertexBuffer(0, this.#mVertexBuffer);

      // let outputBuffer = null;
      // let outputStagingBuffer = null;

      for (const [zIndex, texLayers] of texLayersMap) {
        if (!texLayers || texLayers.length == 0) {
          continue;
        }

        for (const texLayer of texLayers) {
          texLayer.unlock();

          const texLayerType = texLayer.getTextureLayerType();
          if (texLayerType == RenderConst.TEX_LAYER_TYPE.UNKNOWN) {
            continue;
          }

          let uvCoords = texLayer.getUVCoords();
          if (!uvCoords) {
            continue;
          }

          const canvasW = this.#mCanvas.width;
          const canvasH = this.#mCanvas.height;
          let viewport = texLayer.getViewport();

          if (
            !viewport ||
            Number.isNaN(viewport.x) ||
            Number.isNaN(viewport.y) ||
            Number.isNaN(viewport.w) ||
            Number.isNaN(viewport.h) ||
            viewport.x < 0 ||
            viewport.y < 0
          ) {
            continue;
          }

          // next, here is a workaround for handling the actual viewport out of the canvas
          // solution: if the viewport is out of the canvas but the gap within 5 pixels,
          // we are going to change the viewport to adapt the size of the canvas and content.
          if (viewport.x + viewport.w > canvasW) {
            let gapPixels = viewport.x + viewport.w - canvasW;
            if (gapPixels > 0 && gapPixels <= RenderConst.MAX_GAP_PIXELS) {
              viewport.w -= gapPixels;
              if (viewport.w <= 0) {
                viewport.w = 1;
              }
            } else {
              continue;
            }
          }

          if (viewport.y + viewport.h > canvasH) {
            let gapPixels = viewport.y + viewport.h - canvasH;
            if (gapPixels > 0 && gapPixels <= RenderConst.MAX_GAP_PIXELS) {
              viewport.h -= gapPixels;
              if (viewport.h <= 0) {
                viewport.h = 1;
              }
            } else {
              continue;
            }
          }

          const pipelineResBundle = this.#evalPipelineResBundle(texLayer);
          if (!pipelineResBundle) {
            continue;
          }

          // outputBuffer = pipelineResBundle.outputBuffer;
          // outputStagingBuffer = pipelineResBundle.outputStagingBuffer;

          let uvCoordsBuffer = texLayer.getUVCoordsBuffer();
          if (!uvCoordsBuffer) {
            const keyTag = uvCoords.byteLength;
            uvCoordsBuffer = this.#mBufferMgr.acquireBuffer(
              keyTag,
              GPUBufferUsage.VERTEX | GPUBufferUsage.COPY_DST,
              uvCoords.byteLength,
              false,
              false
            );
            texLayer.setUVCoordsBuffer(uvCoordsBuffer);
          }

          this.#mDevice.queue.writeBuffer(
            uvCoordsBuffer,
            0,
            uvCoords,
            0,
            uvCoords.length
          );

          renderPass.setVertexBuffer(1, uvCoordsBuffer);
          renderPass.setViewport(
            viewport.x,
            viewport.y,
            viewport.w,
            viewport.h,
            viewport.minDepth,
            viewport.maxDepth
          );

          const pipeline = pipelineResBundle.pipeline;
          renderPass.setPipeline(pipeline);

          // create a bind group and set to render pass
          const bindGroup = this.#mDevice.createBindGroup({
            layout: pipeline.getBindGroupLayout(0),
            entries: pipelineResBundle.entries,
          });

          renderPass.setBindGroup(0, bindGroup);
          renderPass.draw(6, 1, 0, 0);

          // const renderBundle = this.#prerecordToBundleEncoders(
          //   pipelineResBundle.pipelineType,
          //   bundleEncoderComponents
          // );

          // if (renderBundle) {
          //   renderPass.executeBundles([renderBundle]);
          // }
        }
      }

      renderPass.end();
      // commandEncoder.copyBufferToBuffer(
      //   outputBuffer,
      //   0,
      //   outputStagingBuffer,
      //   0,
      //   1024
      // );

      this.#globalCommandEncoderSubmit();

      // outputStagingBuffer.mapAsync(GPUMapMode.READ, 0, 1024).then(() => {
      //   const copyArrayBuffer = outputStagingBuffer.getMappedRange(0, 1024);
      //   const data = copyArrayBuffer.slice();
      //   outputStagingBuffer.unmap();
      //   console.log(new Float32Array(data));
      // });

      // this.#mDevice.popErrorScope().then((error) => {
      //   if (error) {
      //     console.error(`popErrorScope() error:${JSON.stringify(error)}`);
      //   }
      // });
    } catch (error) {
      globaltracing_error(
        `[WebGUPRenderer] renderNoMsaa() error:${error.message}`
      );
    } finally {
      // to recycle the used buffers
      // this step is pretty important for resources reusing
      this.#recycleInUsedGPUBuffers(texLayersMap);
    }
  }

  isDimensionsOverMaxDimension2DSize(width, height) {
    let isOver = false;
    let maxDimension = 0;
    const featuresHelper = this.#mResMgr.acquireGPUFeaturesHelper();
    if (featuresHelper) {
      maxDimension = featuresHelper.queryMaxTextureDimension2D();
      if (maxDimension > 0) {
        isOver = width > maxDimension || height > maxDimension;
      }
    }

    if (isOver) {
      console.log(
        `[WebGPURenderer] isDimensionsOverMaxDimension2DSize() w:${width} h:${height} max:${maxDimension}`
      );

      add_monitor(
        `[WebGPURenderer] isDimensionsOverMaxDimension2DSize() w:${width} h:${height} max:${maxDimension}`
      );
    }

    return isOver;
  }

  isBufferSizeOverMaxSize(bufferSize) {
    let isOver = false;
    let maxBufferSize = 0;
    const featuresHelper = this.#mResMgr.acquireGPUFeaturesHelper();
    if (featuresHelper) {
      maxBufferSize = featuresHelper.queryMaxBufferSize();
      if (maxBufferSize > 0) {
        isOver = bufferSize > maxBufferSize;
      }
    }

    if (isOver) {
      console.log(
        `[WebGPURenderer] isBufferSizeOverMaxSize() bufferSize:${bufferSize} max:${maxBufferSize}`
      );

      add_monitor(
        `[WebGPURenderer] isBufferSizeOverMaxSize() bufferSize:${bufferSize} max:${maxBufferSize}`
      );
    }

    return isOver;
  }

  #renderWithMsaa(renderDisplays) {
    if (!this.#mDevice || !this.#mWGPUContext) {
      return;
    }

    // this.#mDevice.pushErrorScope('validation');

    // 1. create a buffer for vertex coord
    const vertexBuffer = this.#mDevice.createBuffer({
      label: 'VertexBuffer',
      size: this.#vtxCoordArray.byteLength,
      usage: GPUBufferUsage.VERTEX | GPUBufferUsage.STORAGE,
      mappedAtCreation: true,
    });

    new Float32Array(vertexBuffer.getMappedRange()).set(this.#vtxCoordArray);
    vertexBuffer.unmap();
    const commandEncoder = this.#acquireGlobalCommandEncoder();
    const texLayersMap = this.#evalTextureLayers(renderDisplays);
    for (const [zIndex, texLayers] of texLayersMap) {
      this.#prepareRenderingResource(zIndex, texLayers, commandEncoder);
    }

    const multisamplingTex = this.#acquireMultisamplingTextureForCanvas(
      this.#mCanvas
    );
    const desc = this.#evalRenderPassDescriptorWithMsaa(
      0,
      multisamplingTex.createView(),
      this.#mWGPUContext.getCurrentTexture().createView()
    );
    const renderPass = commandEncoder.beginRenderPass(desc);
    renderPass.setVertexBuffer(0, vertexBuffer);

    for (const [zIndex, texLayers] of texLayersMap) {
      if (!texLayers || texLayers.length == 0) {
        continue;
      }

      for (const texLayer of texLayers) {
        texLayer.unlock();

        const texLayerType = texLayer.getTextureLayerType();
        if (texLayerType == RenderConst.TEX_LAYER_TYPE.UNKNOWN) {
          continue;
        }

        let uvCoords = texLayer.getUVCoords();
        if (!uvCoords) {
          continue;
        }

        let uvCoordsBuffer = texLayer.getUVCoordsBuffer();
        if (!uvCoordsBuffer) {
          const keyTag = uvCoords.byteLength;
          uvCoordsBuffer = this.#mBufferMgr.acquireBuffer(
            keyTag,
            GPUBufferUsage.VERTEX |
              GPUBufferUsage.STORAGE |
              GPUBufferUsage.COPY_DST,
            uvCoords.byteLength,
            false,
            true
          );
          texLayer.setUVCoordsBuffer(uvCoordsBuffer);
        }

        this.#mDevice.queue.writeBuffer(
          uvCoordsBuffer,
          0,
          uvCoords,
          0,
          uvCoords.length
        );

        renderPass.setVertexBuffer(1, uvCoordsBuffer);

        const canvasW = this.#mCanvas.width;
        const canvasH = this.#mCanvas.height;
        let viewport = texLayer.getViewport();
        if (
          !viewport ||
          Number.isNaN(viewport.x) ||
          Number.isNaN(viewport.y) ||
          Number.isNaN(viewport.w) ||
          Number.isNaN(viewport.h) ||
          viewport.x < 0 ||
          viewport.y < 0 ||
          viewport.x + viewport.w > canvasW ||
          viewport.y + viewport.h > canvasH
        ) {
          continue;
        }

        const pipelineResBundle = this.#evalPipelineResBundle(texLayer, true);
        if (!pipelineResBundle) {
          continue;
        }

        renderPass.setViewport(
          viewport.x,
          viewport.y,
          viewport.w,
          viewport.h,
          viewport.minDepth,
          viewport.maxDepth
        );

        const pipeline = pipelineResBundle.pipeline;
        renderPass.setPipeline(pipeline);

        // create a bind group and set to render pass
        const bindGroup = this.#mDevice.createBindGroup({
          layout: pipeline.getBindGroupLayout(0),
          entries: pipelineResBundle.entries,
        });

        renderPass.setBindGroup(0, bindGroup);
        renderPass.draw(6, 1, 0, 0);
      }
    }

    renderPass.end();
    this.#globalCommandEncoderSubmit();

    renderDisplays.forEach((renderDisplay) => {
      renderDisplay.markRenderingStatePending();
    });
  }

  updateVertexCoords(vtxCoords) {
    this.#updateVtxCoords(vtxCoords);
  }

  #updateVtxCoords(vtxCoords) {
    if (!Array.isArray(vtxCoords)) return;

    let flattenVtxCoords = [];
    for (let i = 0; i < vtxCoords.length; ++i) {
      let vtxCoord = vtxCoords[i];
      flattenVtxCoords.push(vtxCoord.x);
      flattenVtxCoords.push(vtxCoord.y);
    }

    this.#vtxCoordArray.set(flattenVtxCoords, 0);
  }

  createRGBATexture(
    commandEncoder,
    index,
    w,
    h,
    rgbaData,
    cachedRbgaTex = null
  ) {
    if (index < 0) {
      globaltracing_error(`[createRGBATexture] ${index} is an invalid index!`);
      return null;
    }

    if (!this.#mBufferMgr) {
      console.warn('[createRGBATexture] buffer manager is not ready!');
      return null;
    }

    if (!this.#mDevice) {
      console.warn('[createRGBATexture] GPUDevice is not ready!');
      return null;
    }

    if (rgbaData == null || rgbaData === undefined) {
      console.warn('[createRGBATexture] rgbaData is invalid!');
      return null;
    }

    if (!commandEncoder) {
      console.warn('[createRGBATexture] command encoder is invalid!');
      return null;
    }

    const bytesPerRow = this.#align(Uint32Array.BYTES_PER_ELEMENT * w, 256);
    const stagingBufUsage = GPUBufferUsage.MAP_WRITE | GPUBufferUsage.COPY_SRC;
    const texUsage =
      GPUTextureUsage.TEXTURE_BINDING |
      GPUTextureUsage.COPY_DST |
      GPUTextureUsage.COPY_SRC;

    let rgbaTexture = null;
    const needToDropTexture =
      cachedRbgaTex != null &&
      (w != cachedRbgaTex.width || h != cachedRbgaTex.height);
    let recreateTexture = true;

    if (cachedRbgaTex) {
      if (needToDropTexture) {
        this.#mTextureMgr.recycleTexture(cachedRbgaTex);
        recreateTexture = true;
      } else {
        rgbaTexture = cachedRbgaTex;
        recreateTexture = false;
      }
    }

    if (recreateTexture) {
      const texConfig = this.#mTextureMgr.assembleTextureConfig(
        w,
        h,
        texUsage,
        'rgba8unorm',
        1
      );
      rgbaTexture = this.#mTextureMgr.acquireTexture(texConfig);
      if (rgbaTexture) {
        rgbaTexture.label = `RD(${index})-rgbaTexture`;
      }
    }

    const stagingBuffer = this.#mBufferMgr.acquireBuffer(
      `${index}_Y`,
      stagingBufUsage,
      bytesPerRow * h,
      true,
      false
    );
    const bufferArray = new Uint8Array(stagingBuffer.getMappedRange());
    const texStride = w * Uint32Array.BYTES_PER_ELEMENT;

    for (let i = 0; i < h; ++i) {
      bufferArray.set(
        rgbaData.subarray(i * texStride, (i + 1) * texStride),
        i * bytesPerRow
      );
    }

    stagingBuffer.unmap();

    commandEncoder.copyBufferToTexture(
      {
        // source
        buffer: stagingBuffer,
        offset: 0,
        bytesPerRow: bytesPerRow,
        rowsPerImage: h,
      },
      {
        // dest
        texture: rgbaTexture,
      },
      [w, h, 1]
    );

    return rgbaTexture;
  }

  #evalYuvTextureGroup(texLayer, commandEncoder, cachedTexGroup = null) {
    const texBufGroup = texLayer.getTextureBufferGroup();

    if (!this.#mDevice) {
      console.warn('[evalYuvTextureGroup] GPUDevice is not ready!');
      if (texBufGroup && texBufGroup.buffer) {
        texBufGroup.buffer.unmap();
      }
      return null;
    }

    if (!commandEncoder) {
      console.warn('[evalYuvTextureGroup] command encoder is invalid!');
      if (texBufGroup && texBufGroup.buffer) {
        texBufGroup.buffer.unmap();
      }
      return null;
    }

    if (!texBufGroup) {
      return cachedTexGroup ? cachedTexGroup : null;
    }

    if (texBufGroup.buffer.mapState != 'unmapped') {
      texBufGroup.buffer.unmap();
    }

    // use cached textures or create textures
    let yPlaneTex = null;
    let uPlaneTex = null;
    let vPlaneTex = null;

    const w = texLayer.getWidth();
    const h = texLayer.getHeight();
    const needToDropTextureGroup =
      cachedTexGroup != null &&
      (w != cachedTexGroup.yPlaneTex.width ||
        h != cachedTexGroup.yPlaneTex.height);
    let recreateTextureGroup = true;

    if (cachedTexGroup) {
      if (needToDropTextureGroup) {
        texLayer.destroyTextureGroup(this.#mTextureMgr);
        recreateTextureGroup = true;
      } else {
        yPlaneTex = cachedTexGroup.yPlaneTex;
        uPlaneTex = cachedTexGroup.uPlaneTex;
        vPlaneTex = cachedTexGroup.vPlaneTex;
        recreateTextureGroup = false;
      }
    }

    let isNV12Format = false;
    let uvTexFormat = 'r8unorm';
    if (texBufGroup && texBufGroup.bufferConfig) {
      if (texBufGroup.bufferConfig.colorFormat == 'nv12') {
        uvTexFormat = 'rg8unorm';
        isNV12Format = true;
      }
    }

    if (recreateTextureGroup) {
      const index = texLayer.getIndex();
      const texUsage =
        GPUTextureUsage.TEXTURE_BINDING |
        GPUTextureUsage.COPY_DST |
        GPUTextureUsage.COPY_SRC;

      /* create new GPUTexture */
      const yPlaneTexConfig = this.#mTextureMgr.assembleTextureConfig(
        w,
        h,
        texUsage,
        'r8unorm',
        1
      );

      const uvPlaneTexConfig = this.#mTextureMgr.assembleTextureConfig(
        w / 2,
        h / 2,
        texUsage,
        uvTexFormat,
        1
      );

      yPlaneTex = this.#mTextureMgr.acquireTexture(yPlaneTexConfig);
      if (yPlaneTex) {
        yPlaneTex.label = `RD(${index})-YPlaneTexture`;
      }

      if (isNV12Format) {
        uPlaneTex = this.#mTextureMgr.acquireTexture(uvPlaneTexConfig);
        if (uPlaneTex) {
          uPlaneTex.label = `RD(${index})-UVPlaneTexture`;
        }
      } else {
        uPlaneTex = this.#mTextureMgr.acquireTexture(uvPlaneTexConfig);
        if (uPlaneTex) {
          uPlaneTex.label = `RD(${index})-UPlaneTexture`;
        }

        vPlaneTex = this.#mTextureMgr.acquireTexture(uvPlaneTexConfig);
        if (vPlaneTex) {
          vPlaneTex.label = `RD(${index})-VPlaneTexture`;
        }
      }
    }

    commandEncoder.copyBufferToTexture(
      {
        // source
        buffer: texBufGroup.buffer,
        offset: texBufGroup.yPlaneBuffer.offset,
        bytesPerRow: texBufGroup.yPlaneBuffer.bytesPerRow,
        rowsPerImage: texBufGroup.yPlaneBuffer.rowsPerImage,
      },
      {
        // dest
        texture: yPlaneTex,
      },
      [w, h, 1]
    );

    commandEncoder.copyBufferToTexture(
      {
        // source
        buffer: texBufGroup.buffer,
        offset: texBufGroup.uPlaneBuffer.offset,
        bytesPerRow: texBufGroup.uPlaneBuffer.bytesPerRow,
        rowsPerImage: texBufGroup.uPlaneBuffer.rowsPerImage,
      },
      {
        // dest
        texture: uPlaneTex,
      },
      [w / 2, h / 2, 1]
    );

    if (texBufGroup.vPlaneBuffer.offset > 0 && !isNV12Format) {
      commandEncoder.copyBufferToTexture(
        {
          // source
          buffer: texBufGroup.buffer,
          offset: texBufGroup.vPlaneBuffer.offset,
          bytesPerRow: texBufGroup.vPlaneBuffer.bytesPerRow,
          rowsPerImage: texBufGroup.vPlaneBuffer.rowsPerImage,
        },
        {
          // dest
          texture: vPlaneTex,
        },
        [w / 2, h / 2, 1]
      );
    }

    let textures = {};
    textures.yPlaneTex = yPlaneTex;
    textures.uPlaneTex = uPlaneTex;
    textures.vPlaneTex = vPlaneTex;
    return textures;
  }

  #evalRgbaTexture(texLayer, commandEncoder, cachedTex = null) {
    const texBuffer = texLayer.getTextureBufferGroup();

    if (!this.#mDevice) {
      console.warn('[evalRgbaTexture] GPUDevice is not ready!');
      if (texBuffer && texBuffer.buffer) {
        texBuffer.buffer.unmap();
      }
      return null;
    }

    if (!commandEncoder) {
      console.warn('[evalRgbaTexture] command encoder is invalid!');
      if (texBuffer && texBuffer.buffer) {
        texBuffer.buffer.unmap();
      }
      return null;
    }

    if (!texBuffer) {
      return cachedTex ? cachedTex : null;
    }

    if (texBuffer.buffer.mapState != 'unmapped') {
      texBuffer.buffer.unmap();
    }

    const index = texLayer.getIndex();
    const w = texLayer.getWidth();
    const h = texLayer.getHeight();
    const texUsage =
      GPUTextureUsage.TEXTURE_BINDING |
      GPUTextureUsage.COPY_DST |
      GPUTextureUsage.COPY_SRC;

    let rgbaTexture = null;
    const needToDropTexture =
      cachedTex != null && (w != cachedTex.width || h != cachedTex.height);
    let recreateTexture = true;

    if (cachedTex) {
      if (needToDropTexture) {
        texLayer.destroyTextureGroup(this.#mTextureMgr);
        recreateTexture = true;
      } else {
        rgbaTexture = cachedTex;
        recreateTexture = false;
      }
    }

    if (recreateTexture) {
      const texConfig = this.#mTextureMgr.assembleTextureConfig(
        w,
        h,
        texUsage,
        'rgba8unorm',
        1
      );

      rgbaTexture = this.#mTextureMgr.acquireTexture(texConfig);
      if (rgbaTexture) {
        rgbaTexture.label = `RD(${index})-rgbaTexture`;
      }
    }

    commandEncoder.copyBufferToTexture(
      {
        // source
        buffer: texBuffer.buffer,
        offset: 0,
        bytesPerRow: texBuffer.bytesPerRow,
        rowsPerImage: texBuffer.rowsPerImage,
      },
      {
        // dest
        texture: rgbaTexture,
      },
      [w, h, 1]
    );

    return rgbaTexture;
  }

  /**
   * Write yuv raw data to the texture buffers.
   *
   * @param {*} yuvData raw yuv data
   * @param {*} texBufferGroup buffers that will be written to
   * @returns textureBufferGroup itself
   */
  writeToYuvTexturesBufferGroup(yuvData, texBufferGroup) {
    if (
      !this.#mBufferMgr ||
      !yuvData ||
      !texBufferGroup ||
      !texBufferGroup.buffer ||
      !texBufferGroup.bufferConfig ||
      texBufferGroup.buffer.mapState != 'mapped'
    ) {
      return null;
    }

    let yPlane = yuvData.yPlane;
    let uPlane = yuvData.cbPlane;
    let vPlane = yuvData.crPlane;

    const yPlaneBytesPerRow = this.#align(
      Uint8Array.BYTES_PER_ELEMENT * yPlane.width,
      256
    );

    let elBytes = Uint8Array.BYTES_PER_ELEMENT;
    if (texBufferGroup.bufferConfig.colorFormat == 'nv12') {
      elBytes = Uint16Array.BYTES_PER_ELEMENT;
    }

    const uvPlaneBytesPerRow = this.#align(elBytes * uPlane.width, 256);

    const yPlaneOffset = 0;
    const uPlaneOffset = yPlaneBytesPerRow * yPlane.height;
    let vPlaneOffset = 0;
    let bufferSize =
      yPlaneBytesPerRow * yPlane.height + uvPlaneBytesPerRow * uPlane.height;
    if (vPlane && vPlane.buffer) {
      bufferSize += uvPlaneBytesPerRow * vPlane.height;
      vPlaneOffset =
        yPlaneOffset + uPlaneOffset + uvPlaneBytesPerRow * uPlane.height;
    }

    let stagingBuffer = texBufferGroup.buffer;
    let bufferArray = texBufferGroup.bufferArray;
    if (!bufferArray) {
      bufferArray = stagingBuffer.getMappedRange(0, bufferSize);
      texBufferGroup.bufferArray = bufferArray;
    }
    const mappedArray = new Uint8Array(bufferArray);
    const yStride = yPlane.width * Uint8Array.BYTES_PER_ELEMENT;

    for (let i = 0; i < yPlane.height; ++i) {
      mappedArray.set(
        yPlane.buffer.subarray(i * yStride, (i + 1) * yStride),
        yPlaneOffset + i * yPlaneBytesPerRow
      );
    }

    let uvStride = yStride / 2;
    if (vPlaneOffset == 0) {
      uvStride = uPlane.width * Uint16Array.BYTES_PER_ELEMENT;
    }

    for (let i = 0; i < uPlane.height; ++i) {
      mappedArray.set(
        uPlane.buffer.subarray(i * uvStride, (i + 1) * uvStride),
        uPlaneOffset + i * uvPlaneBytesPerRow
      );
    }

    if (vPlaneOffset > 0) {
      for (let i = 0; i < vPlane.height; ++i) {
        mappedArray.set(
          vPlane.buffer.subarray(i * uvStride, (i + 1) * uvStride),
          vPlaneOffset + i * uvPlaneBytesPerRow
        );
      }
    }

    // must call unmap() to change the buffer mapState
    // stagingBuffer.unmap();

    texBufferGroup.buffer = stagingBuffer;
    texBufferGroup.yPlaneBuffer = {
      bytesPerRow: yPlaneBytesPerRow,
      rowsPerImage: yPlane.height,
      offset: yPlaneOffset,
    };

    texBufferGroup.uPlaneBuffer = {
      bytesPerRow: uvPlaneBytesPerRow,
      rowsPerImage: uPlane.height,
      offset: uPlaneOffset,
    };

    texBufferGroup.vPlaneBuffer = {
      bytesPerRow: uvPlaneBytesPerRow,
      rowsPerImage: vPlane.height,
      offset: vPlaneOffset,
    };

    return texBufferGroup;
  }

  /**
   * Write raw RGBA data to the texture buffers.
   *
   * @param {*} index the index of the render display
   * @param {*} width width of frame
   * @param {*} height height of frame
   * @param {*} rgbaData raw RGBA data
   * @param {*} texBufferGroup the texture buffers that be written to
   * @returns the textureBufferGroup itself
   */
  writeToRgbaTextureBuffer(index, width, height, rgbaData, texBufferGroup) {
    if (
      !this.#mBufferMgr ||
      !rgbaData ||
      !texBufferGroup ||
      !texBufferGroup.buffer
    ) {
      return null;
    }

    const bytesPerRow = this.#align(Uint32Array.BYTES_PER_ELEMENT * width, 256);
    const stagingBuffer = texBufferGroup.buffer;

    let bufferArray = texBufferGroup.bufferArray;
    if (!bufferArray) {
      bufferArray = stagingBuffer.getMappedRange();
      texBufferGroup.bufferArray = bufferArray;
    }

    const mappedArray = new Uint8Array(bufferArray);
    const texStride = width * Uint32Array.BYTES_PER_ELEMENT;

    for (let i = 0; i < height; ++i) {
      mappedArray.set(
        rgbaData.subarray(i * texStride, (i + 1) * texStride),
        i * bytesPerRow
      );
    }

    // DON'T call unmap() here. If a buffer unmaps here,
    // CPU will cost more resources if rendering not come
    // but data updates happen twice or more times
    // stagingBuffer.unmap();

    texBufferGroup.buffer = stagingBuffer;
    texBufferGroup.bytesPerRow = bytesPerRow;
    texBufferGroup.rowsPerImage = height;
    return texBufferGroup;
  }

  produceVideoFrame(data, init) {
    return new VideoFrame(data, init);
  }

  writeUniformBuffer(label, uniformBufArray, cachedBuffer = null) {
    if (!this.#mDevice) {
      globaltracing_error('writeUniformBuffer() GPUDevice is not ready yet.');
      return null;
    }

    if (!uniformBufArray || uniformBufArray.length == 0) {
      return null;
    }

    let buffer = cachedBuffer;
    if (!buffer) {
      buffer = this.#mDevice.createBuffer({
        label: label,
        size: uniformBufArray.byteLength,
        usage: GPUBufferUsage.UNIFORM | GPUBufferUsage.COPY_DST,
      });
    }

    this.#mDevice.queue.writeBuffer(
      buffer,
      0,
      uniformBufArray,
      0,
      uniformBufArray.length
    );

    return buffer;
  }

  // #prerecordToBundleEncoders(renderPipelineType, bundleEncoderComponents) {
  //   if (!this.#mDevice || !bundleEncoderComponents) {
  //     return;
  //   }

  //   let renderBundle = null;
  //   let bundleEncoderInst = this.#mBundleEncodersMap.get(renderPipelineType);
  //   if (!bundleEncoderInst) {
  //     const bundleEncoder = this.#mDevice.createRenderBundleEncoder({
  //       colorFormats: [this.#mFormat],
  //     });

  //     // bundleEncoder.setPipeline(bundleEncoderComponents.pipeline);
  //     bundleEncoder.setVertexBuffer(0, bundleEncoderComponents.vertexBuffer);
  //     // bundleEncoder.setVertexBuffer(1, bundleEncoderComponents.coordsBuffer);
  //     // bundleEncoder.setBindGroup(0, bundleEncoderComponents.bindGroup);
  //     // bundleEncoder.draw(6, 1, 0, 0);
  //     renderBundle = bundleEncoder.finish();

  //     bundleEncoderInst = {};
  //     bundleEncoderInst.bundleEncoder = bundleEncoder;
  //     bundleEncoderInst.components = bundleEncoderComponents;
  //     bundleEncoderInst.renderBundle = renderBundle;
  //     this.#mBundleEncodersMap.set(renderPipelineType, bundleEncoderInst);
  //   } else {
  //     renderBundle = bundleEncoderInst.renderBundle;
  //   }

  //   return renderBundle;
  // }

  // #getBundleEncoderInst(renderPipelineType) {
  //   return this.#mBundleEncodersMap.get(renderPipelineType);
  // }

  #align(n, alignment) {
    return Math.ceil(n / alignment) * alignment;
  }

  #acquireGlobalCommandEncoder() {
    if (!this.#mDevice) {
      globaltracing_error(
        'GPUDevice is not ready! No available command encoder.'
      );
      return null;
    }

    if (!this.#mGlobalCommandEncoder) {
      this.#mGlobalCommandEncoder = this.#mDevice.createCommandEncoder();
    }

    return this.#mGlobalCommandEncoder;
  }

  #globalCommandEncoderSubmit(watermarkCommandEncoder = null) {
    if (this.#mGlobalCommandEncoder && watermarkCommandEncoder) {
      this.#mDevice.queue.submit([
        this.#mGlobalCommandEncoder.finish(),
        watermarkCommandEncoder.finish(),
      ]);
    } else if (this.#mGlobalCommandEncoder) {
      this.#mDevice.queue.submit([this.#mGlobalCommandEncoder.finish()]);
    } else if (watermarkCommandEncoder) {
      this.#mDevice.queue.submit([watermarkCommandEncoder.finish()]);
    }

    this.#mGlobalCommandEncoder = null;
  }

  #acquireCursorTextureRenderPipeline(zIndex, isMultisampled) {
    if (!this.#mDevice) {
      return null;
    }

    if (!this.#mCursorTextureRenderPipeline) {
      let bindGroupLayout = this.#mDevice.createBindGroupLayout({
        label: 'CursorTexBindGroupLayout',
        entries: [
          {
            binding: 0,
            visibility: GPUShaderStage.FRAGMENT,
            sampler: {},
          },
          {
            binding: 1,
            visibility: GPUShaderStage.FRAGMENT,
            texture: {
              sampleType: 'float',
              viewDimension: '2d',
            },
          },
          {
            binding: 2,
            visibility: GPUShaderStage.FRAGMENT,
            buffer: {
              type: 'uniform',
            },
          },
        ],
      });

      if (!bindGroupLayout) {
        return null;
      }

      const pipelineLayout = this.#mDevice.createPipelineLayout({
        label: `CursorTexPipelineLayout(${zIndex})`,
        bindGroupLayouts: [bindGroupLayout],
      });

      const renderPipelineDesc = {
        label: `CursorTexRenderPipeline(${zIndex})`,
        layout: pipelineLayout,
        vertex: {
          module: this.#mDevice.createShaderModule({
            code: RenderConst.CURSOR_SHADER,
          }),
          entryPoint: 'v_main',
          buffers: [
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 0,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 1,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
          ],
        },
        fragment: {
          module: this.#mDevice.createShaderModule({
            code: RenderConst.CURSOR_SHADER,
          }),
          entryPoint: 'f_main',
          targets: [
            {
              format: this.#mFormat,
              blend: {
                color: {
                  operation: 'add',
                  srcFactor: 'src-alpha',
                  dstFactor: 'one-minus-src-alpha',
                },
                alpha: {
                  operation: 'add',
                  srcFactor: 'src-alpha',
                  dstFactor: 'one-minus-src-alpha',
                },
              },
            },
          ],
        },
        primitive: {
          topology: 'triangle-list',
        },
      };

      if (isMultisampled) {
        renderPipelineDesc.multisample = { count: 4 };
      }

      this.#mCursorTextureRenderPipeline =
        this.#mDevice.createRenderPipeline(renderPipelineDesc);
    }

    return this.#mCursorTextureRenderPipeline;
  }

  #acquireWatermarkTextureRenderPipeline(zIndex, isMultisampled) {
    if (!this.#mDevice) {
      return null;
    }

    if (!this.#mWatermarkTextureRenderPipeline) {
      let bindGroupLayout = this.#mDevice.createBindGroupLayout({
        label: 'WatermarkTexBindGroupLayout',
        entries: [
          {
            binding: 0,
            visibility: GPUShaderStage.FRAGMENT,
            sampler: {},
          },
          {
            binding: 1,
            visibility: GPUShaderStage.FRAGMENT,
            texture: {
              sampleType: 'float',
              viewDimension: '2d',
            },
          },
        ],
      });

      if (!bindGroupLayout) {
        return null;
      }

      const pipelineLayout = this.#mDevice.createPipelineLayout({
        label: `WatermarkTexPipelineLayout(${zIndex})`,
        bindGroupLayouts: [bindGroupLayout],
      });

      const renderPipelineDesc = {
        label: `WatermarkTexRenderPipeline(${zIndex})`,
        layout: pipelineLayout,
        vertex: {
          module: this.#mDevice.createShaderModule({
            code: RenderConst.WATERMARK_SHADER,
          }),
          entryPoint: 'v_main',
          buffers: [
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 0,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 1,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
          ],
        },
        fragment: {
          module: this.#mDevice.createShaderModule({
            code: RenderConst.WATERMARK_SHADER,
          }),
          entryPoint: 'f_main',
          targets: [
            {
              format: this.#mFormat,
              blend: {
                color: {
                  operation: 'add',
                  srcFactor: 'src-alpha',
                  dstFactor: 'one-minus-src-alpha',
                },
                alpha: {
                  operation: 'add',
                  srcFactor: 'src-alpha',
                  dstFactor: 'one-minus-src-alpha',
                },
              },
            },
          ],
        },
        primitive: {
          topology: 'triangle-list',
        },
      };

      if (isMultisampled) {
        renderPipelineDesc.multisample = { count: 4 };
      }

      this.#mWatermarkTextureRenderPipeline =
        this.#mDevice.createRenderPipeline(renderPipelineDesc);
    }

    return this.#mWatermarkTextureRenderPipeline;
  }

  acquireBlendTextureSampler() {
    if (!this.#mDevice) {
      return null;
    }

    if (!this.#mBlendTextureSampler) {
      this.#mBlendTextureSampler = this.#mDevice.createSampler({});
    }

    return this.#mBlendTextureSampler;
  }

  configureGPUContext(canvas) {
    if (this.#mWGPUContext) {
      return;
    }

    this.#mWGPUContext = canvas.getContext('webgpu');
    if (this.#mWGPUContext) {
      this.#mWGPUContext.configure({
        device: this.#mDevice,
        format: this.#mFormat,
        alphaMode: 'premultiplied',
      });
    } else {
      globaltracing_error(
        `configureGPUContext() webgpuContext is invalid! canvas=${canvas}`
      );
    }
  }

  unconfigureGPUContext() {
    if (this.#mWGPUContext) {
      this.#mWGPUContext.unconfigure();
      this.#mWGPUContext = null;
    }
  }

  acquireVideoFrameRenderPipeline(shader, isMultisampled) {
    if (!this.#mDevice || !shader) {
      return null;
    }

    if (!this.#mVideoFrameRenderPipeline) {
      const bindGroupLayout = this.#mDevice.createBindGroupLayout({
        label: 'VideoFrameBindGroupLayout',
        entries: [
          {
            binding: 0,
            visibility: GPUShaderStage.FRAGMENT,
            sampler: {},
          },
          {
            binding: 1,
            visibility: GPUShaderStage.FRAGMENT,
            externalTexture: {},
          },
        ],
      });

      const pipelineLayout = this.#mDevice.createPipelineLayout({
        label: 'VideoFramePipelineLayout',
        bindGroupLayouts: [bindGroupLayout],
      });

      const renderPipelineDesc = {
        label: 'VideoFrameRenderPipeline',
        layout: pipelineLayout,
        vertex: {
          module: this.#mDevice.createShaderModule({ code: shader }),
          entryPoint: 'vertex_main',
          buffers: [
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 0,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 1,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
          ],
        },
        fragment: {
          module: this.#mDevice.createShaderModule({ code: shader }),
          entryPoint: 'fragment_main',
          targets: [
            {
              format: this.#mFormat,
            },
          ],
        },
        primitive: {
          topology: 'triangle-list',
        },
      };

      if (isMultisampled) {
        renderPipelineDesc.multisample = { count: 4 };
      }

      this.#mVideoFrameRenderPipeline =
        this.#mDevice.createRenderPipeline(renderPipelineDesc);
    }

    return this.#mVideoFrameRenderPipeline;
  }

  #acquireYuvTextureI420RenderPipeline(shader, isMultisampled = false) {
    if (!this.#mDevice || !shader) {
      return null;
    }

    if (!this.#mI420YuvTextureRenderPipeline) {
      const bindGroupLayout = this.#mDevice.createBindGroupLayout({
        label: 'YuvBindGroupLayout',
        entries: [
          {
            binding: 0,
            visibility: GPUShaderStage.FRAGMENT,
            sampler: {},
          },
          {
            binding: 1,
            visibility: GPUShaderStage.FRAGMENT,
            sampler: {},
          },
          {
            binding: 2,
            visibility: GPUShaderStage.FRAGMENT,
            texture: {
              sampleType: 'float',
              viewDimension: '2d',
            },
          },
          {
            binding: 3,
            visibility: GPUShaderStage.FRAGMENT,
            texture: {
              sampleType: 'float',
              viewDimension: '2d',
            },
          },
          {
            binding: 4,
            visibility: GPUShaderStage.FRAGMENT,
            texture: {
              sampleType: 'float',
              viewDimension: '2d',
            },
          },
          {
            binding: 5,
            visibility: GPUShaderStage.FRAGMENT,
            buffer: {
              type: 'uniform',
            },
          },
          {
            binding: 6,
            visibility: GPUShaderStage.VERTEX,
            buffer: {
              type: 'uniform',
            },
          },
          // {
          //   binding: 7,
          //   visibility: GPUShaderStage.FRAGMENT,
          //   buffer: {
          //     type: 'storage'
          //   },
          // },
        ],
      });

      const pipelineLayout = this.#mDevice.createPipelineLayout({
        label: 'YuvPipelineLayout',
        bindGroupLayouts: [bindGroupLayout],
      });

      const renderPipelineDesc = {
        label: 'YuvRenderPipeline',
        layout: pipelineLayout,
        vertex: {
          module: this.#mDevice.createShaderModule({ code: shader }),
          entryPoint: 'vertex_main',
          buffers: [
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 0,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 1,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
          ],
        },
        fragment: {
          module: this.#mDevice.createShaderModule({ code: shader }),
          entryPoint: 'fragment_main',
          targets: [
            {
              format: this.#mFormat,
            },
          ],
        },
        primitive: {
          topology: 'triangle-list',
        },
      };

      if (isMultisampled) {
        renderPipelineDesc.multisample = { count: 4 };
      }

      this.#mI420YuvTextureRenderPipeline =
        this.#mDevice.createRenderPipeline(renderPipelineDesc);
    }

    return this.#mI420YuvTextureRenderPipeline;
  }

  #acquireYuvTextureNV12RenderPipeline(shader, isMultisampled = false) {
    if (!this.#mDevice || !shader) {
      return null;
    }

    if (!this.#mNV12YuvTextureRenderPipeline) {
      const bindGroupLayout = this.#mDevice.createBindGroupLayout({
        label: 'YuvBindGroupLayout',
        entries: [
          {
            binding: 0,
            visibility: GPUShaderStage.FRAGMENT,
            sampler: {},
          },
          {
            binding: 1,
            visibility: GPUShaderStage.FRAGMENT,
            sampler: {},
          },
          {
            binding: 2,
            visibility: GPUShaderStage.FRAGMENT,
            texture: {
              sampleType: 'float',
              viewDimension: '2d',
            },
          },
          {
            binding: 3,
            visibility: GPUShaderStage.FRAGMENT,
            texture: {
              sampleType: 'float',
              viewDimension: '2d',
            },
          },
          {
            binding: 4,
            visibility: GPUShaderStage.FRAGMENT,
            buffer: {
              type: 'uniform',
            },
          },
          {
            binding: 5,
            visibility: GPUShaderStage.VERTEX,
            buffer: {
              type: 'uniform',
            },
          },
          // {
          //   binding: 6,
          //   visibility: GPUShaderStage.FRAGMENT,
          //   buffer: {
          //     type: 'storage',
          //   },
          // },
        ],
      });

      const pipelineLayout = this.#mDevice.createPipelineLayout({
        label: 'YuvPipelineLayout',
        bindGroupLayouts: [bindGroupLayout],
      });

      const renderPipelineDesc = {
        label: 'YuvRenderPipeline',
        layout: pipelineLayout,
        vertex: {
          module: this.#mDevice.createShaderModule({ code: shader }),
          entryPoint: 'vertex_main',
          buffers: [
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 0,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
            {
              arrayStride: 2 * 4,
              attributes: [
                {
                  shaderLocation: 1,
                  format: 'float32x2',
                  offset: 0,
                },
              ],
            },
          ],
        },
        fragment: {
          module: this.#mDevice.createShaderModule({ code: shader }),
          entryPoint: 'fragment_main',
          targets: [
            {
              format: this.#mFormat,
            },
          ],
        },
        primitive: {
          topology: 'triangle-list',
        },
      };

      if (isMultisampled) {
        renderPipelineDesc.multisample = { count: 4 };
      }

      this.#mNV12YuvTextureRenderPipeline =
        this.#mDevice.createRenderPipeline(renderPipelineDesc);
    }

    return this.#mNV12YuvTextureRenderPipeline;
  }

  acquireVideoFrameSampler() {
    if (!this.#mDevice) {
      return null;
    }

    if (!this.#mVideoFrameSampler) {
      this.#mVideoFrameSampler = this.#mDevice.createSampler({
        addressModeU: 'clamp-to-edge',
        addressModeV: 'clamp-to-edge',
        magFilter: 'linear',
        minFilter: 'linear',
        mipmapFilter: 'linear',
      });
    }

    return this.#mVideoFrameSampler;
  }

  acquireYuvTexturesSamplers() {
    if (!this.#mDevice) {
      return null;
    }

    if (!this.#mYuvTexturesSamplers) {
      this.#mYuvTexturesSamplers = [];
      const yPlaneSampler = this.#mDevice.createSampler({
        addressModeU: 'clamp-to-edge',
        addressModeV: 'clamp-to-edge',
        magFilter: 'linear',
        minFilter: 'linear',
        mipmapFilter: 'linear',
      });

      const uvPlaneSampler = this.#mDevice.createSampler({
        addressModeU: 'clamp-to-edge',
        addressModeV: 'clamp-to-edge',
        magFilter: 'linear',
        minFilter: 'linear',
        mipmapFilter: 'linear',
      });

      this.#mYuvTexturesSamplers.push(yPlaneSampler);
      this.#mYuvTexturesSamplers.push(uvPlaneSampler);
    }

    return this.#mYuvTexturesSamplers;
  }

  #evalRenderPassDescriptorWithMsaa(zIndex, textureView, resolveTarget) {
    const label = `renderPass - ${zIndex}`;
    return {
      label: label,
      colorAttachments: [
        {
          view: textureView,
          resolveTarget: resolveTarget,
          loadOp: 'clear',
          storeOp: 'discard',
        },
      ],
    };
  }

  #evalRenderPassDescriptorNoMsaa(textureView) {
    const label = `RenderPassNoMsaa`;
    if (textureView) {
      textureView.label = 'canvas-texture-view';
    }

    return {
      label: label,
      colorAttachments: [
        {
          view: textureView,
          loadOp: 'clear',
          storeOp: 'store',
        },
      ],
    };
  }

  #evalTextureLayers(renderDisplays) {
    let renderingTexLayersMap = new Map();
    for (let i = 0; i < renderDisplays.length; ++i) {
      const renderDisplay = renderDisplays[i];
      const texLayersMap = renderDisplay.getTextureLayersMap();

      for (
        let zIdx = RenderConst.TEX_LAYER_Z_IDX.VS_BASE;
        zIdx < RenderConst.TEX_LAYER_Z_IDX.END;
        ++zIdx
      ) {
        if (texLayersMap.has(zIdx)) {
          const texLayer = texLayersMap.get(zIdx);
          if (texLayer) {
            let entryVal = renderingTexLayersMap.get(zIdx);
            if (!entryVal) {
              entryVal = [];
            }

            entryVal.push(texLayer);
            renderingTexLayersMap.set(zIdx, entryVal);
          }
        }
      }
    }

    return renderingTexLayersMap;
  }

  #evalPipelineResBundle(texLayer, isMultisampled = false) {
    let renderPipeline = null;
    let samplers = null;
    let entries = null;
    let renderPipelineType = null;

    // const outputBuffer = this.#mDevice.createBuffer({
    //   size: 1024,
    //   usage: GPUBufferUsage.STORAGE | GPUBufferUsage.COPY_SRC,
    // });

    // const outputStagingBuffer = this.#mDevice.createBuffer({
    //   size: 1024,
    //   usage: GPUBufferUsage.MAP_READ | GPUBufferUsage.COPY_DST,
    // });

    const zIdx = texLayer.getZIndex();
    const texType = texLayer.getTextureType();
    const colorFormat = texLayer.getColorFormat();

    if (zIdx == RenderConst.TEX_LAYER_Z_IDX.VS_BASE) {
      if (texType == RenderConst.TEX_TYPE.EXTERNAL_TEX) {
        renderPipelineType = RenderConst.RENDER_PIPELINE_TYPE.VIDEO_FRAME;
        renderPipeline = this.acquireVideoFrameRenderPipeline(
          RenderConst.VIDEO_FRAME_SHADER,
          isMultisampled
        );
        samplers = this.acquireVideoFrameSampler();
        const vf = texLayer.getPendingVideoFrame();
        if (!vf || vf.codedWidth == 0 || vf.codedHeight == 0 || !vf.format) {
          texLayer.setPendingVideoFrame(null);
          return null;
        }

        entries = [
          { binding: 0, resource: samplers },
          {
            binding: 1,
            resource: this.#mDevice.importExternalTexture({ source: vf }),
          },
        ];
      } else if (texType == RenderConst.TEX_TYPE.GPU_TEX_YUV) {
        if (colorFormat == 'i420') {
          renderPipelineType = RenderConst.RENDER_PIPELINE_TYPE.YUV_I420;
          renderPipeline = this.#acquireYuvTextureI420RenderPipeline(
            RenderConst.VIDEO_YUV_I420_SHADER,
            isMultisampled
          );
        } else if (colorFormat == 'nv12') {
          renderPipelineType = RenderConst.RENDER_PIPELINE_TYPE.YUV_NV12;
          renderPipeline = this.#acquireYuvTextureNV12RenderPipeline(
            RenderConst.VIDEO_YUV_NV12_SHADER,
            isMultisampled
          );
        }

        samplers = this.acquireYuvTexturesSamplers();
        const uniformBuffer = texLayer.getUniformBuffer();
        if (!uniformBuffer) {
          return null;
        }

        const texGroup = texLayer.getTextureGroup();
        if (texGroup) {
          if (colorFormat == 'i420') {
            entries = [
              { binding: 0, resource: samplers[0] },
              { binding: 1, resource: samplers[1] },
              { binding: 2, resource: texGroup.yPlaneTex.createView() },
              { binding: 3, resource: texGroup.uPlaneTex.createView() },
              { binding: 4, resource: texGroup.vPlaneTex.createView() },
              { binding: 5, resource: { buffer: uniformBuffer } },
              { binding: 6, resource: { buffer: uniformBuffer } },
            ];
          } else if (colorFormat == 'nv12') {
            entries = [
              { binding: 0, resource: samplers[0] },
              { binding: 1, resource: samplers[1] },
              { binding: 2, resource: texGroup.yPlaneTex.createView() },
              { binding: 3, resource: texGroup.uPlaneTex.createView() },
              { binding: 4, resource: { buffer: uniformBuffer } },
              { binding: 5, resource: { buffer: uniformBuffer } },
              // { binding: 6, resource: { buffer: outputBuffer } },
            ];
          }
        }
      }
    } else if (
      zIdx == RenderConst.TEX_LAYER_Z_IDX.WATERMARK ||
      zIdx == RenderConst.TEX_LAYER_Z_IDX.MASK
    ) {
      renderPipelineType = RenderConst.RENDER_PIPELINE_TYPE.RGBA_WATERMARK;
      renderPipeline = this.#acquireWatermarkTextureRenderPipeline(
        zIdx,
        isMultisampled
      );
      samplers = this.acquireBlendTextureSampler();
      const watermarkTex = texLayer.getTextureGroup();
      if (watermarkTex) {
        entries = [
          { binding: 0, resource: samplers },
          { binding: 1, resource: watermarkTex.createView() },
        ];
      }
    } else if (zIdx == RenderConst.TEX_LAYER_Z_IDX.CURSOR) {
      renderPipelineType = RenderConst.RENDER_PIPELINE_TYPE.RGBA_CURSOR;
      renderPipeline = this.#acquireCursorTextureRenderPipeline(
        zIdx,
        isMultisampled
      );
      samplers = this.acquireBlendTextureSampler();

      const uniformBuffer = texLayer.getUniformBuffer();
      if (!uniformBuffer) {
        return null;
      }

      const cursorTex = texLayer.getTextureGroup();
      if (cursorTex) {
        entries = [
          { binding: 0, resource: samplers },
          { binding: 1, resource: cursorTex.createView() },
          { binding: 2, resource: { buffer: uniformBuffer } },
        ];
      }
    }

    if (
      !renderPipeline ||
      !samplers ||
      !entries ||
      renderPipelineType == null
    ) {
      return null;
    }

    const pipelineResBundle = {
      pipelineType: renderPipelineType,
      pipeline: renderPipeline,
      entries: entries,
      // outputBuffer: outputBuffer,
      // outputStagingBuffer: outputStagingBuffer,
    };

    return pipelineResBundle;
  }

  #prepareRenderingResource(zIndex, texLayers, commandEncoder) {
    for (const texLayer of texLayers) {
      const texType = texLayer.getTextureType();
      if (texType == RenderConst.TEX_TYPE.EXTERNAL_TEX) {
        continue;
      }

      if (zIndex == RenderConst.TEX_LAYER_Z_IDX.VS_BASE) {
        this.#prepareYuvTexLayerRenderingResource(texLayer, commandEncoder);
      } else if (
        zIndex == RenderConst.TEX_LAYER_Z_IDX.CURSOR ||
        zIndex == RenderConst.TEX_LAYER_Z_IDX.WATERMARK ||
        zIndex == RenderConst.TEX_LAYER_Z_IDX.MASK
      ) {
        this.#prepareRgbaTexLayerRenderingResource(texLayer, commandEncoder);
      }
    }
  }

  #prepareYuvTexLayerRenderingResource(texLayer, commandEncoder) {
    let texGroup = texLayer.getTextureGroup();
    if (!texGroup) {
      // means no textures group now, need to create a group of textures
      texGroup = this.#evalYuvTextureGroup(texLayer, commandEncoder, null);
      texLayer.setIsNew(false);
    } else {
      // means has textures group now, if w or h of a texture is changed,
      // we need to create a new textures group for this tex layer
      if (texLayer.isNew()) {
        texGroup = this.#evalYuvTextureGroup(
          texLayer,
          commandEncoder,
          texGroup
        );
        texLayer.setIsNew(false);
      }
    }

    if (texGroup) {
      texLayer.setTextureGroup(texGroup);
    } else {
      // console.log('[prepareYuvTexLayerRenderingResource()] failed in creating texture group! wait for next round...');
    }
  }

  #prepareRgbaTexLayerRenderingResource(texLayer, commandEncoder) {
    let rgbaTex = texLayer.getTextureGroup();
    if (!rgbaTex) {
      // means no textures group now, need to create a group of textures
      rgbaTex = this.#evalRgbaTexture(texLayer, commandEncoder, null);
      texLayer.setIsNew(false);
    } else {
      // means has textures group now, if w or h of a texture is changed,
      // we need to create a new textures group for this tex layer
      if (texLayer.isNew()) {
        rgbaTex = this.#evalRgbaTexture(texLayer, commandEncoder, rgbaTex);
        texLayer.setIsNew(false);
      } else {
        // console.log(`prepareYuvTexLayerRenderingResource() raw data is old, just use the cached texture`);
      }
    }

    if (rgbaTex) {
      texLayer.setTextureGroup(rgbaTex);
    } else {
      // console.log('[prepareYuvTexLayerRenderingResource()] failed in creating texture group! wait for next round...');
    }
  }

  #acquireMultisamplingTextureForCanvas(canvas) {
    if (!canvas || !this.#mDevice) {
      return this.#mMultisamplingTexture ? this.#mMultisamplingTexture : null;
    }

    if (!this.#mMultisamplingTexture) {
      const msTexConfig = this.#mTextureMgr.assembleTextureConfig(
        canvas.width,
        canvas.height,
        GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.RENDER_ATTACHMENT,
        this.#mFormat,
        4
      );
      this.#mMultisamplingTexture =
        this.#mTextureMgr.acquireTexture(msTexConfig);
    } else {
      if (
        this.#mMultisamplingTexture.width != canvas.width ||
        this.#mMultisamplingTexture.height != canvas.height
      ) {
        const msTexConfig = this.#mTextureMgr.assembleTextureConfig(
          canvas.width,
          canvas.height,
          GPUTextureUsage.TEXTURE_BINDING | GPUTextureUsage.RENDER_ATTACHMENT,
          this.#mFormat,
          4
        );
        this.#mMultisamplingTexture =
          this.#mTextureMgr.acquireTexture(msTexConfig);
      }
    }

    return this.#mMultisamplingTexture;
  }

  /**
   * To clear the color attachment of the base canvas.
   */
  clearAttachedCanvas() {
    if (!this.#mDevice || !this.#mWGPUContext || !this.#mVertexBuffer) {
      return;
    }

    if (
      !this.#mCanvas ||
      this.#mCanvas.width == 0 ||
      this.#mCanvas.height == 0
    ) {
      return;
    }

    const commandEncoder = this.#mDevice.createCommandEncoder();
    const passEncoder = commandEncoder.beginRenderPass({
      colorAttachments: [
        {
          view: this.#mWGPUContext.getCurrentTexture().createView(),
          clearValue: { r: 0.0, g: 0.0, b: 0.0, a: 1.0 },
          loadOp: 'clear',
          storeOp: 'store',
        },
      ],
    });

    const pipeline = this.#mDevice.createRenderPipeline({
      layout: 'auto',
      vertex: {
        module: this.#mDevice.createShaderModule({
          code: RenderConst.CLEAR_SHADER,
        }),
        entryPoint: 'v_main',
        buffers: [
          {
            arrayStride: 2 * 4,
            attributes: [
              {
                shaderLocation: 0,
                format: 'float32x2',
                offset: 0,
              },
            ],
          },
        ],
      },
      fragment: {
        module: this.#mDevice.createShaderModule({
          code: RenderConst.CLEAR_SHADER,
        }),
        entryPoint: 'f_main',
        targets: [
          {
            format: this.#mFormat,
          },
        ],
      },
      primitive: {
        topology: 'triangle-list',
      },
    });

    passEncoder.setVertexBuffer(0, this.#mVertexBuffer);
    passEncoder.setPipeline(pipeline);
    passEncoder.draw(6);
    passEncoder.end();
    this.#mDevice.queue.submit([commandEncoder.finish()]);
  }

  clear() {
    console.log('WebGPURender.clear');
  }

  /**
   * Cleanup the attached resources in renderer.
   *
   * Note: we can't do any operation to destroy the resources, that will be done
   * by other managers. So here we just need to cleanup the references, that's enough.
   */
  cleanup() {
    if (process.env.NODE_ENV === 'development') {
      console.log('WebGPURender.cleanup');
    }
    this.unconfigureGPUContext();
    this.#mCanvas = null;

    if (this.#mBufferMgr) {
      this.#mBufferMgr = null;
    }

    if (this.#mDevice) {
      this.#mDevice = null;
    }
  }

  /**
   * Request a GPUBuffer from GPUBufferPool.
   *
   * @param {*} bufferConfig config of buffer
   * @returns null or an available(mapped) GPUBuffer
   */
  requestTextureBuffer(bufferConfig) {
    if (!this.#mBufferPool) {
      return null;
    }

    const stagingBufUsage = GPUBufferUsage.MAP_WRITE | GPUBufferUsage.COPY_SRC;
    bufferConfig.usage = stagingBufUsage;
    return this.#mBufferPool.acquire(bufferConfig);
  }

  recycleTextureBufferGroup(texLayer) {
    if (texLayer) {
      if (this.#mResMgr) {
        const bufferPool = this.#mResMgr.acquireGPUBufferPool();
        if (bufferPool) {
          const bufferGroup = texLayer.getTextureBufferGroup();
          if (bufferGroup && bufferGroup.buffer) {
            if (bufferGroup.bufferArray) {
              bufferGroup.bufferArray = null;
            }
            bufferPool.recycle(bufferGroup.buffer, bufferGroup.bufferConfig);
            texLayer.destroyTextureBufferGroup(this.#mResMgr);
          }
        }
      }
    }
  }

  #recycleInUsedGPUBuffers(texLayersMap) {
    for (const [zIndex, texLayers] of texLayersMap) {
      for (const texLayer of texLayers) {
        if (texLayer) {
          const texBufferGroup = texLayer.getTextureBufferGroup();
          if (texBufferGroup && texBufferGroup.buffer) {
            if (texBufferGroup.bufferArray) {
              texBufferGroup.bufferArray = null;
            }

            this.#mBufferPool.recycle(
              texBufferGroup.buffer,
              texBufferGroup.bufferConfig
            );
          } else {
            // const count = this.#mBufferPool.getInUsedPoolCount();
            // console.log(`renderNoMsaa() recycle is skip! inUsedPoolCount=${count}`);
          }
          texLayer.destroyTextureBufferGroup(this.#mResMgr);
        }
      }
    }
  }
}

export default WebGPURenderer;
